import Controller from '@ember/controller';
import { action } from '@ember/object';
import { camelize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { DateTime, Interval } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import BusinessesBusinessFeedUsageRoute from 'vault-client/routes/businesses/business/feed-usage';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CurrentFeedIngredients, FeedIngredient } from 'vault-client/types/graphql-types';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';

type FeedUsageRow = {
	[key: string]: number | string;
	date: string;
};

function* months(interval: Interval) {
	let cursor = interval.start.startOf('month');
	while (cursor < interval.end) {
		yield cursor;
		cursor = cursor.plus({ month: 1 });
	}
}

export default class FeedUsageController extends Controller {
	MONTH_COLUMN_ID = '9ae4d96d-03ca-42d0-8687-c4087cff9867';

	// Used to calculate a column width based on ingredient name length
	PIXELS_PER_HEADER_CHAR = 8;
	HEADER_PADDING = 32;

	@tracked startDate: string = DateTime.now().startOf('month').toISODate();
	@tracked endDate: string = DateTime.now().plus({ months: 11 }).endOf('month').toISODate();
	@tracked selectedRows: FeedUsageRow[] = [];

	@tracked showCreateNewIngredientModal: boolean = false;
	@tracked feedIngredients: FeedIngredient[] = [];

	declare model: ModelFrom<BusinessesBusinessFeedUsageRoute>;

	feedIngredientRoutePath = '';
	feedOverviewRoutePath = '';

	uuidNamespace = 'e27e345b-a2d6-4f48-beab-f66b8a1f4f4f';

	queryParams = ['startDate', 'endDate'];

	timePeriodOptions: UiDateFilterOption[] = [
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.now().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 18 Months',
			startDate: DateTime.now().minus({ months: 18 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.now().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 18 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 17 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	];

	get tableRoute() {
		return `feed-usage.${this.model.businessId}-table`;
	}

	get currentTimePeriodOption() {
		return (
			this.timePeriodOptions.find((option) => option.startDate === this.startDate && option.endDate === this.endDate) ?? {
				startDate: this.startDate,
				endDate: this.endDate,
			}
		);
	}

	get isUsageEmpty() {
		return !this.rows.some((v) => Object.values(v).filter((x) => !!x).length > 2);
	}

	get feedIngredientIdToName() {
		const dict: { [key: string]: string } = {};

		this.model.getFeedUsage.data?.FeedIngredients.forEach((ingredient) => {
			dict[ingredient.versionedConceptSeriesId] = ingredient.name;
		});

		return dict;
	}

	get ingredientsAreVisible() {
		const visibleCols = this.columns.filter((column) => column.isVisible);

		// If 2 or more columns are visible, then at least one ingredient is shown. If only one column is visible, ensure it is not the month column
		return visibleCols.length >= 2 || visibleCols.firstObject?.id !== this.MONTH_COLUMN_ID;
	}

	get columns(): TableColumn[] {
		const feedIngredientColumns: TableColumn[] =
			this.feedIngredients
				.map((feedIngredient): TableColumn => {
					return {
						id: feedIngredient.versionedConceptSeriesId,
						name: feedIngredient.name,
						valuePath: camelize(feedIngredient.name + ' Forecasted'),
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						width: feedIngredient.name.length * this.PIXELS_PER_HEADER_CHAR + this.HEADER_PADDING,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					};
				})
				.sortBy('name') ?? [];

		return [
			{
				id: '9ae4d96d-03ca-42d0-8687-c4087cff9867',
				name: 'Month',
				valuePath: 'month',
				cellComponent: CellComponents.MonthFormat,
				maxWidth: 90,
				isVisible: true,
				isFixed: 'left',
				textAlign: 'left',
			},
			...feedIngredientColumns,
		];
	}

	get rows() {
		const map = new Map();

		this.model.getFeedUsage.data?.AggregateFeedIngredientForecastedUsages?.forEach((aggregateUsage) => {
			const year = aggregateUsage.year;
			const month = aggregateUsage.month;
			const id = aggregateUsage?.FeedIngredient?.versionedConceptSeriesId;
			const drymatterPercent = aggregateUsage.FeedIngredient?.dryMatterPercent;

			if (!year || !month || !id || drymatterPercent == null) {
				console.warn(`Month, Year, drymatterPercent, and feedIngredientId are required. Please ensure they were requested`);
				return;
			}

			if (!this.feedIngredients.find((ingredient) => ingredient.versionedConceptSeriesId === id)) {
				// Ingredient usage retrieved, but the ingredient is not active at the moment
				return;
			}

			const date = DateTime.local(year, month).toISODate();

			if (!map.get(date)) {
				map.set(date, { month: date, id: date });
			}

			const forecastedValuePath = `${camelize(this.feedIngredientIdToName[id] + ' Forecasted')}`;
			const row = map.get(date);

			row[forecastedValuePath] = aggregateUsage.sum.dmiUsageInTons ?? null;
		});

		const interval = Interval.fromISO(`${this.startDate}/${this.endDate}`);
		const currentMonths: { [key: string]: number | undefined } = {};

		// Make dict of months that are already present
		for (const currentMonth of map.keys()) {
			const date = DateTime.fromISO(currentMonth).startOf('month').toISODate();
			currentMonths[date] = 1;
		}

		// Fill in month gaps if necessary
		for (const date of months(interval)) {
			if (!currentMonths[date.toISODate()]) {
				map.set(date.toISODate(), { month: date.toISODate(), id: date.toISODate() });
			}
		}

		return Array.from(map.values()).sortBy('month');
	}

	get inactiveFeedIngredients() {
		const activeIngredientsDict: { [id: string]: 1 | undefined } = {};
		this.feedIngredients.forEach((v) => {
			activeIngredientsDict[v.versionedConceptSeriesId] = 1;
		});

		return this.model.getFeedUsage.data?.FeedIngredients.filter((v) => !activeIngredientsDict[v.versionedConceptSeriesId]);
	}

	@action
	openCreateNewIngredientModal() {
		this.showCreateNewIngredientModal = true;
	}

	@action
	closeCreateNewIngredientModal() {
		this.showCreateNewIngredientModal = false;
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	@action
	onCreateUpdateFeedIngredient(currentFeedIngredients: CurrentFeedIngredients | null, ingredientName: string) {
		const ingredient = currentFeedIngredients?.FeedIngredients?.find((ingredient) => ingredient.name === ingredientName);
		if (!ingredient) return;

		this.addFeedIngredient(ingredient);
	}

	@action
	addFeedIngredient(ingredient: FeedIngredient | null) {
		if (!ingredient) return;

		for (const x of this.feedIngredients) {
			if (x.versionedConceptSeriesId === ingredient.versionedConceptSeriesId) {
				return;
			}
		}

		this.feedIngredients = [...this.feedIngredients, ingredient];
		this.setStoredFeedIngredients();
		this.updateVaultTableLocalStorage(ingredient);
	}

	@action
	removeFeedIngredient(ingredient: FeedIngredient) {
		this.feedIngredients = this.feedIngredients.filter((x) => {
			return x.versionedConceptSeriesId !== ingredient.versionedConceptSeriesId;
		});
		this.setStoredFeedIngredients();
	}

	@action
	setStoredFeedIngredients() {
		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(
				`feed-usage.${this.model.businessId}`,
				JSON.stringify(this.feedIngredients.map((ingredient) => ingredient.versionedConceptSeriesId))
			);
		}
	}

	@action
	fetchStoredFeedIngredients() {
		if (checkStorageAvailable('localStorage')) {
			this.feedIngredients = [];
			const temp = window.localStorage.getItem(`feed-usage.${this.model.businessId}`);
			if (temp) {
				const storedFeedIngredientIDs: string[] = JSON.parse(temp);
				for (const id of storedFeedIngredientIDs) {
					const ingredient = this.model.getFeedUsage.data?.FeedIngredients.find((ingredient) => {
						return id === ingredient.versionedConceptSeriesId;
					});

					if (ingredient) {
						this.feedIngredients = [...this.feedIngredients, ingredient];
					}
				}
			}
		}
	}

	@action
	updateVaultTableLocalStorage(ingredient: FeedIngredient) {
		if (checkStorageAvailable('localStorage')) {
			const ingredientId = ingredient.versionedConceptSeriesId;
			const columnId = ingredientId;
			const temp = window.localStorage.getItem(this.tableRoute);
			if (temp) {
				const storedTableColumns: TableColumn[] = JSON.parse(temp);
				const storedColumn = storedTableColumns.find((col) => col.id === columnId);
				const activeColumn = this.columns.find((col) => col.id === columnId);

				if (storedColumn) {
					storedColumn.isVisible = true;
				} else if (activeColumn) {
					storedTableColumns.push(activeColumn);
				} else {
					// Column wasn't stored by vault table, or found in current columns (This should not happen)
					const newCol = {
						id: ingredient.versionedConceptSeriesId,
						name: ingredient.name,
						valuePath: camelize(ingredient.name + ' Forecasted'),
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						width: ingredient.name.length * this.PIXELS_PER_HEADER_CHAR + this.HEADER_PADDING,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
					};
					storedTableColumns.push(newCol);
				}

				window.localStorage.setItem(this.tableRoute, JSON.stringify(storedTableColumns));
			}
		}
	}
}
