import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { FeedIngredientFilterDTO, Query } from 'vault-client/types/graphql-types';

const GET_FEED_INGREDIENTS = gql`
	query LivestockFeedIngredients($feedIngredientsWhere: FeedIngredientFilterDTO) {
		FeedIngredients(where: $feedIngredientsWhere) {
			id
			name
			description
			dryMatterPercent
			flatPricePerTon
			cmePercentageBasis
			cmeUsdBasis
			FeedCategory {
				id
				name
				defaultCmePercentageBasis
				defaultCmeUsdBasis
				defaultFlatPricePerTon
				HedgeProduct {
					id
					slug
					MostCurrentFuture {
						SymbolGroup {
							displayFactor
						}
					}
				}
			}
		}
	}
`;

export type GetFeedIngredientsQuery = {
	__typename?: 'Query';
	FeedIngredients: Query['FeedIngredients'];
};

type GetFeedIngredientsQueryVariables = {
	feedIngredientsWhere?: FeedIngredientFilterDTO;
};

export default class LivestockFeedIngredientsRoute extends Route {
	templateName = 'livestock-feed/ingredients/index';

	@tracked variables: GetFeedIngredientsQueryVariables = {};

	getFeedIngredients = useQuery<GetFeedIngredientsQuery, GetFeedIngredientsQueryVariables>(this, () => [
		GET_FEED_INGREDIENTS,
		{ variables: this.variables },
	]);

	generateFeedIngredientsWhere(businessId: string | null) {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		where.FeedIngredientVersion = {
			isCurrent: { equals: true },
		};

		return where;
	}
}
