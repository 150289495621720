import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import FeedUsageController from 'vault-client/controllers/feed/usage';
import { FeedIngredientFilterDTO, FeedIngredientForecastedUsageFilterDTO, Query } from 'vault-client/types/graphql-types';

interface ModelParams {
	startDate: string;
	endDate: string;
}

const GET_FEED_USAGE = gql`
	query FeedUsage(
		$feedIngredientsWhere: FeedIngredientFilterDTO
		$AggregateFeedIngredientUsagesWhere: FeedIngredientForecastedUsageFilterDTO
	) {
		FeedIngredients(where: $feedIngredientsWhere) {
			id
			dryMatterPercent
			versionedConceptSeriesId
			name
		}
		AggregateFeedIngredientForecastedUsages(
			calc: { sum: { dmiUsageInTons: true } }
			groupBy: { month: true, year: true, FeedIngredient: { versionedConceptSeriesId: true, dryMatterPercent: true } }
			where: $AggregateFeedIngredientUsagesWhere
		) {
			year
			month
			sum {
				dmiUsageInTons
			}
			FeedIngredient {
				versionedConceptSeriesId
				dryMatterPercent
			}
		}
	}
`;

export type GetFeedUsageQuery = {
	__typename?: 'Query';
	FeedIngredients: Query['FeedIngredients'];
	AggregateFeedIngredientForecastedUsages: Query['AggregateFeedIngredientForecastedUsages'];
};

type GetFeedUsageQueryVariables = {
	feedIngredientsWhere?: FeedIngredientFilterDTO;
	AggregateFeedIngredientUsagesWhere?: FeedIngredientForecastedUsageFilterDTO;
};

export default class FeedUsageRoute extends Route {
	templateName = 'feed/usage';

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	@tracked variables: GetFeedUsageQueryVariables = {};

	getFeedUsage = useQuery<GetFeedUsageQuery, GetFeedUsageQueryVariables>(this, () => [GET_FEED_USAGE, { variables: this.variables }]);

	generateFeedIngredientsWhere(businessId: string | null) {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		where.FeedIngredientVersion = {
			isCurrent: {
				equals: true,
			},
		};

		return where;
	}

	generateAggregateFeedIngredientUsageWhere(businessId: string | null, startDate: string | undefined, endDate: string | undefined) {
		const where: FeedIngredientForecastedUsageFilterDTO = {};

		if (startDate) {
			if (!where.date) {
				where.date = {};
			}

			where.date.gte = startDate;
		}

		if (endDate) {
			if (!where.date) {
				where.date = {};
			}

			where.date.lte = endDate;
		}

		if (businessId) {
			where.FeedIngredient = { customerId: { equals: businessId } };
		}

		return where;
	}

	async model(params: ModelParams) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
			AggregateFeedIngredientUsagesWhere: this.generateAggregateFeedIngredientUsageWhere(businessId, params.startDate, params.endDate),
		};
		await this.getFeedUsage.promise;

		return {
			getFeedUsage: this.getFeedUsage,
			businessId,
		};
	}

	resetController(controller: FeedUsageController, isExiting: boolean) {
		if (isExiting) {
			controller.set('selectedRows', []);
		}
	}
}
