import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import LivestockFeedUsageController from 'vault-client/controllers/livestock-feed/usage';
import {
	FeedIngredientFilterDTO,
	FeedIngredientForecastedUsageFilterDTO,
	Query,
	SortByDirection,
	SwineLivestockGroupFeedUsageFilterDTO,
	SwineLivestockGroupFilterDTO,
	SwineLivestockGroupSortByDTO,
} from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface ModelParams {
	startDate: string;
	endDate: string;
	versionId: string | undefined;
}

const GET_FEED_USAGE = gql`
	query LivestockFeedUsage(
		$businessId: String!
		$swineLivestockGroupsWhere: SwineLivestockGroupFilterDTO
		$swineLivestockGroupFeedUsagesWhere: SwineLivestockGroupFeedUsageFilterDTO
		$swineLivestockGroupsOrderBy: SwineLivestockGroupSortByDTO
		$feedIngredientsWhere: FeedIngredientFilterDTO
	) {
		FeedIngredients(where: $feedIngredientsWhere) {
			id
			FeedCategory {
				id
				name
			}
			versionedConceptSeriesId
			name
		}
		SwineLivestockGroups(where: $swineLivestockGroupsWhere, orderBy: $swineLivestockGroupsOrderBy) {
			id
			name
			updatedAt
			versionId
			minAgeInWeeks
			maxAgeInWeeks
			versionedConceptSeriesId
			Version {
				id
				name
				isCurrent
				updatedAt
				CreatedByUser {
					id
					firstName
					lastName
				}
				LastUpdatedByUser {
					id
					firstName
					lastName
				}
			}
		}
		Customer(id: $businessId) {
			id
			name
			businessRoles
		}
		SwineLivestockGroupFeedUsages(where: $swineLivestockGroupFeedUsagesWhere) {
			id
			Version {
				id
				name
				isCurrent
				updatedAt
				LastUpdatedByUser {
					firstName
					lastName
				}
			}
			LivestockGroup {
				id
				name
			}
			FeedIngredient {
				id
				versionedConceptSeriesId
				name
			}
			versionId
			usageInLbsPerHead
			usageInTonsPerHead
			feedIngredientId
			versionedConceptSeriesId
		}
	}
`;

export type GetFeedUsageQuery = {
	__typename?: 'Query';
	SwineLivestockGroups: Query['SwineLivestockGroups'];
	FeedIngredients: Query['FeedIngredients'];
	Customer: Query['Customer'];
	SwineLivestockGroupFeedUsages: Query['SwineLivestockGroupFeedUsages'];
};

type GetFeedUsageQueryVariables = {
	businessId?: string;
	aggregateFeedIngredientUsagesWhere?: FeedIngredientForecastedUsageFilterDTO;
	swineLivestockGroupsWhere?: SwineLivestockGroupFilterDTO;
	swineLivestockGroupsOrderBy?: SwineLivestockGroupSortByDTO;
	swineLivestockGroupFeedUsagesWhere?: SwineLivestockGroupFeedUsageFilterDTO;
	feedIngredientsWhere?: FeedIngredientFilterDTO;
};

export default class LivestockFeedUsageRoute extends Route {
	templateName = 'livestock-feed/usage';

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
		versionId: { refreshModel: true },
	};

	@tracked variables: GetFeedUsageQueryVariables = {};

	getFeedUsage = useQuery<GetFeedUsageQuery, GetFeedUsageQueryVariables>(this, () => [GET_FEED_USAGE, { variables: this.variables }]);

	generateSwineLivestockGroupsWhere(businessId: string | null) {
		const where: SwineLivestockGroupFilterDTO = {
			LivestockGroupVersion: {
				isCurrent: { equals: true },
			},
		};

		if (businessId) {
			where.businessId = { equals: businessId };
		}

		return where;
	}

	generateSwineLivestockGroupFeedUsagesWhere(businessId: string) {
		const where: SwineLivestockGroupFeedUsageFilterDTO = {};

		if (businessId) {
			where.businessId = { equals: businessId };
		}

		return where;
	}

	generateFeedIngredientsWhere(businessId: string | null): FeedIngredientFilterDTO {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		where.FeedIngredientVersion = {
			isCurrent: {
				equals: true,
			},
		};

		return where;
	}
	async model(_params: ModelParams) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			businessId,
			swineLivestockGroupsWhere: this.generateSwineLivestockGroupsWhere(businessId),
			swineLivestockGroupsOrderBy: {
				minAgeInWeeks: SortByDirection.Asc,
			},
			swineLivestockGroupFeedUsagesWhere: this.generateSwineLivestockGroupFeedUsagesWhere(businessId),
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};

		await this.getFeedUsage.promise;

		return {
			getFeedUsage: this.getFeedUsage,
			businessId,
		};
	}

	setupController(controller: LivestockFeedUsageController, model: ModelFrom<LivestockFeedUsageRoute>, transition: Transition<unknown>) {
		super.setupController(controller, model, transition);
		if (controller.versionId == undefined) {
			const currentVersionId = model.getFeedUsage.data?.SwineLivestockGroupFeedUsages.find((usage) => usage.Version.isCurrent)?.Version.id;
			controller.versionId = currentVersionId;
		}
	}
	resetController(controller: LivestockFeedUsageController, isExiting: boolean) {
		if (isExiting) {
			controller.set('versionId', undefined);
			controller.set('selectedRows', []);
		}
	}
}
