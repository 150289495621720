import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TableColumn, SortObj, CellComponents } from 'vault-client/types/vault-table';
import { getCurrentColumns } from 'vault-client/utils/vault-table-utils';
import { service } from '@ember/service';
import AccessibilityService from 'vault-client/services/accessibility';

interface VaultTableArgs {
	id: string;
	columns: TableColumn[];
	rows: any[];
	footerRows?: any[];
	fullMobileScroll?: boolean;
	disableInternalSorting?: boolean;
	setSorts?: SetSortsFunc;
	setTablePageState?: SetTablePageStateFunc;
	// eslint-disable-next-line no-unused-vars
	setCurrentlySelectedRows?: (_newSelections: any[]) => void;
	showColumnControl: boolean;
	currentlySelectedRows?: any[];
	sorts?: SortObj[];
	tableRoute: string;
	widthConstraint?: string;
	resizeMode?: string;
	fillMode?: string;
	columnControlTrigger?: string;
	disableRowSelectCondition?: (row: unknown) => boolean;
	checkboxSelectionMode?: 'multiple' | 'single';
	hideVerticalBorders?: boolean;
}

interface SetTablePageStateFunc {
	// eslint-disable-next-line no-unused-vars
	(newPageVal?: number): void;
}

interface SetSortsFunc {
	// eslint-disable-next-line no-unused-vars
	(newSorts: SortObj[]): void;
}

export default class VaultTable extends Component<VaultTableArgs> {
	@service declare accessibility: AccessibilityService;

	_fullMobileScroll = false;
	_widthConstraint = 'gte-container';
	_resizeMode = 'standard';
	_fillMode = 'equal-column';
	_columnControlTrigger: string = 'column-control-trigger';

	@tracked _columns: TableColumn[] = [];
	@tracked _sorts: SortObj[] = []; // tracking sorts internally in table will allow to save to local storage
	@tracked showColumnControl: boolean = false;
	originalColumnWidths = new Map<string, { width: number; minWidth: number; maxWidth: number }>();

	get accessibilityModeEnabled(): boolean {
		return this.accessibility.isAccessibilityModeEnabled;
	}

	storeOriginalColumnWidths(columns: TableColumn[]) {
		columns.forEach((column) => {
			if (!this.originalColumnWidths.has(column.id)) {
				// don't overwrite original widths that have been set
				this.originalColumnWidths.set(column.id, {
					width: column.width || 100,
					minWidth: column.minWidth || 80,
					maxWidth: column.maxWidth || 150,
				});
			}

			if (column.subcolumns) {
				this.storeOriginalColumnWidths(column.subcolumns);
			}
		});
	}

	adjustColumnWidths(columns: TableColumn[]): TableColumn[] {
		const defaultColumnIncrease = 1.47;
		const percentValueColumnIncrease = 1.15;

		return columns
			.map((column) => {
				if (column.cellComponent === CellComponents.SelectCheckBox || column.cellComponent === CellComponents.Button) return column;

				const columnValueIsPercentage = column?.componentArgs?.style === 'percent';
				const increaseFactor = columnValueIsPercentage ? percentValueColumnIncrease : defaultColumnIncrease;
				const original = this.originalColumnWidths.get(column.id);

				return original ? this.adjustColumnProperties(column, original, increaseFactor) : column;
			})
			.filter((column): column is TableColumn => column !== undefined);
	}

	adjustColumnProperties(
		column: TableColumn,
		original: { width: number; minWidth: number; maxWidth: number },
		increaseFactor: number,
	): TableColumn {
		const adjustedColumn = {
			...column,
			width: this.accessibilityModeEnabled ? original.width * increaseFactor : original.width,
			minWidth: this.accessibilityModeEnabled ? original.minWidth * increaseFactor : original.minWidth,
			maxWidth: this.accessibilityModeEnabled ? original.maxWidth * increaseFactor : original.maxWidth,
		};

		if (column.subcolumns) {
			adjustedColumn.subcolumns = this.adjustColumnWidths(column.subcolumns);
		}

		return adjustedColumn;
	}

	get columns() {
		// need to always return non-empty columns array to avoid ember-table bug - doesn't have handling for empty columns array
		let columns = this._columns.length > 0 ? this._columns : this.args.columns;
		if (this.showSelectionCheckboxes) {
			const checkboxColumn: TableColumn = {
				id: '84k8ebac-f683-4fb4-871z-5f2fee14c37a',
				name: 'SelectionCheckbox',
				width: 50,
				textAlign: 'center',
				cellComponent: CellComponents.SelectCheckBox,
				isSortable: false,
				isFixed: 'left',
				isVisible: true,
				isResizable: false,
			};

			if (!columns.some((column) => column.name === 'SelectionCheckbox')) {
				columns = [checkboxColumn, ...columns];
			}
		}

		if (this.accessibilityModeEnabled) {
			this.storeOriginalColumnWidths(columns);
			columns = this.adjustColumnWidths(columns);
		}

		return this.filterVisibleColumns(columns);
	}

	set columns(columns) {
		this._columns = columns;
	}

	filterVisibleColumns(columns: TableColumn[]): TableColumn[] {
		const visibleColumns: TableColumn[] = [];
		if (columns.length === 0) {
			console.warn('No columns found. Please ensure a columns array was provided and that at least one column is set to visible');
		}
		columns.forEach((col) => {
			const currentColumn: TableColumn = { ...col };
			if (currentColumn.subcolumns) {
				set(currentColumn, 'subcolumns', this.filterVisibleColumns(currentColumn.subcolumns));
			}
			if (currentColumn.isVisible === true) {
				visibleColumns.push(currentColumn);
			}
		});

		return visibleColumns;
	}

	get sorts(): SortObj[] {
		return this._sorts;
	}

	set sorts(sorts: SortObj[]) {
		this._sorts = sorts;
	}

	get fullMobileScroll(): boolean {
		return this.args.fullMobileScroll ?? this._fullMobileScroll;
	}

	get widthConstraint(): string {
		return this.args.widthConstraint ?? this._widthConstraint;
	}

	get resizeMode(): string {
		return this.args.resizeMode ?? this._resizeMode;
	}

	get fillMode(): string {
		return this.args.fillMode ?? this._fillMode;
	}

	get showHeaders(): boolean {
		const isNotEmptyName = (column: TableColumn) => column.name !== '';
		return this.columns.findIndex(isNotEmptyName) === -1 ? false : true;
	}

	get columnControlTrigger(): HTMLElement | null {
		if (this.args.columnControlTrigger) {
			return document.getElementById(this.args.columnControlTrigger);
		} else {
			return document.getElementById(this._columnControlTrigger);
		}
	}

	get showSelectionCheckboxes(): boolean {
		if (this.args.currentlySelectedRows && this.args.setCurrentlySelectedRows) return true;
		return false;
	}

	get isIndeterminate(): boolean {
		return this.args.currentlySelectedRows?.length && this.args.currentlySelectedRows.length !== this.selectableRows.length ? true : false;
	}

	get isSelectAllChecked(): boolean {
		return this.args.currentlySelectedRows?.length && this.args.currentlySelectedRows.length === this.selectableRows.length ? true : false;
	}

	get selectableRows() {
		const disableRowSelectCondition = this.args.disableRowSelectCondition;
		if (disableRowSelectCondition) {
			return this.args.rows.filter((row) => disableRowSelectCondition(row) === false);
		} else {
			return this.args.rows;
		}
	}

	get checkboxSelectionMode(): 'multiple' | 'single' {
		return this.args.checkboxSelectionMode ?? 'multiple';
	}

	@action
	toggleSelectAll() {
		if (this.args.setCurrentlySelectedRows) {
			if (this.isSelectAllChecked) {
				this.args.setCurrentlySelectedRows([]);
			} else {
				this.args.setCurrentlySelectedRows(this.selectableRows);
			}
		}
	}

	@action
	updateCurrentlySelectedRows(selection: any) {
		if (this.args.setCurrentlySelectedRows) {
			this.args.setCurrentlySelectedRows(selection);
		}
	}

	@action
	rowSelectionMatchFunction(a: TableColumn, b: TableColumn) {
		return a.id === b.id;
	}

	@action
	toggleShowColumnControl() {
		this.showColumnControl = !this.showColumnControl;
	}

	// set table component internal base state upon component insertion into DOM
	@action
	setBaseTableState(): void {
		if (this.args.sorts) {
			this.sorts = this.args.sorts;
		}
	}

	@action
	updateSorts(sorts: SortObj[]): void {
		// updating sorts internally to table
		this.sorts = sorts;
		// optional pass in func used to reset sort value on controller queryParams to preserve in url
		if (this.args.setSorts) this.args.setSorts(sorts);
		// optional pass in func to set state outside of table component (page number, scroll, etc...)
		if (this.args.setTablePageState) this.args.setTablePageState();
	}

	@action
	onChangeColumnState(newColumnsArray: TableColumn[]) {
		this._columns = newColumnsArray;
		this.setStoredTableState(newColumnsArray);
	}

	setStoredTableState(newColumnStateArr: TableColumn[]): void {
		window.localStorage.setItem(this.args.tableRoute, JSON.stringify(newColumnStateArr));
	}

	checkStorageAvailable(type: string): boolean {
		// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
		let storage;
		try {
			storage = window[type as keyof Window];
			const x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// everything except Firefox
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// test name field too, because code might not be present
					// everything except Firefox
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				storage &&
				storage.length !== 0
			);
		}
	}

	@action
	fetchStoredTableState(): void {
		// The columns that are supported, per the code.
		const availableColumns = this.args.columns;

		if (this.checkStorageAvailable('localStorage')) {
			const savedState = window.localStorage.getItem(this.args.tableRoute);
			if (savedState != null) {
				// Get saved columns
				const storedTableState = JSON.parse(savedState);

				const result = getCurrentColumns(storedTableState, availableColumns);
				this.columns = result;
			} else {
				// If there aren't saved columns, return all columns.
				this.columns = availableColumns;
			}
		} else {
			// If there aren't saved columns, return all columns.
			this.columns = availableColumns;
		}
	}
}
