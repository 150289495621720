import LivestockFeedIngredientsRoute from 'vault-client/routes/livestock-feed/ingredients';

export default class BusinessesBusinessLivestockFeedIngredientsRoute extends LivestockFeedIngredientsRoute {
	async model() {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};
		await this.getFeedIngredients.promise;

		return {
			getFeedIngredients: this.getFeedIngredients,
			id: businessId,
		};
	}
}
