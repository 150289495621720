import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { camelize, classify } from '@ember/string';
import { v5 as uuid } from 'uuid';
import {
	TypeOfLedgerEntry,
	FeedIngredient,
	LedgerFeedCategory,
	AggregateLedgerEntryDTO,
	AggregateForecastedMilkProductionByMonthDTO,
	AggregateActualMilkProductionDTO,
	BusinessEntityRole,
	FeedIngredientConsumedAndPurchasedVolume,
} from 'vault-client/types/graphql-types';
import BusinessesBusinessLivestockFeedExpensesRoute from 'vault-client/routes/businesses/business/livestock-feed-expenses';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { convertTonsToPricingUnit, findNearestFuture, getFeedIngredientMarketPrice } from 'vault-client/utils/feed-utils';
import MarketDataService from 'vault-client/services/market-data';
import ENV from 'vault-client/config/environment';
import { service } from '@ember/service';

type AggregateLedgerRow = {
	month: string | number;
	// Procedurally generated keys based on ledger category name
	[key: string]: string | number;
};

type AggregateEntryType = (AggregateLedgerEntryDTO | FeedIngredientConsumedAndPurchasedVolume)[];

interface DateFilterOption {
	displayName: string;
	startDate: string | null;
	endDate: string | null;
}

interface MonthlySwineProduction {
	date: string;
	quantity: number;
}

function* months(interval: Interval) {
	let cursor = interval.start.startOf('month');
	while (cursor < interval.end) {
		yield cursor;
		cursor = cursor.plus({ month: 1 });
	}
}

export default class LivestockFeedExpensesController extends Controller {
	declare model: ModelFrom<BusinessesBusinessLivestockFeedExpensesRoute>;
	@tracked startDate: string | null = DateTime.local().startOf('year').toISODate();
	@tracked endDate: string | null = DateTime.local().endOf('year').toISODate();
	@tracked selectedRows: any[] = [];
	@tracked dynamicUnit: string | null = null;

	@service declare marketData: MarketDataService;

	uuidNamespace = 'ec0281b5-6cf0-4e96-b33a-a9bfaa77fced';
	registeredFutures: string[] = [];

	get showUpdateValuesButton() {
		return this.selectedRows.length > 0;
	}

	get businessRole() {
		const roles = this.model.getLivestockFeedExpenses?.data?.Customer?.businessRoles ?? [];
		if (roles.includes(BusinessEntityRole.HogProducer)) return BusinessEntityRole.HogProducer;
		if (roles.includes(BusinessEntityRole.DairyProducer)) return BusinessEntityRole.DairyProducer;
		return null;
	}

	get ingredientDetailRoutePath() {
		return this.businessRole === BusinessEntityRole.HogProducer
			? 'businesses.business.livestock-feed-ingredient'
			: 'businesses.business.feed-ingredient';
	}

	get isSwineProducer() {
		return this.businessRole === BusinessEntityRole.HogProducer;
	}

	get averageFinishWeightInLbs() {
		return this.model.averageFinishWeightInLbs ?? ENV.APP.DEFAULT_AVG_SWINE_FINISHING_WEIGHT_IN_LBS;
	}

	get averageFinishAgeInWeeks() {
		return this.model.averageFinishAgeInWeeks ?? ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS;
	}

	get monthlySwineProduction(): MonthlySwineProduction[] {
		const monthlyProductionSwine = Object.entries(
			(this.model.getLivestockFeedExpenses.data?.SwinePurchasesAndProduced ?? []).reduce<{ [date: string]: number }>((acc, production) => {
				if (!production.dob) return acc;

				const expectedMarketingDate = DateTime.fromISO(production.dob).plus({
					weeks: this.averageFinishAgeInWeeks,
				});

				const marketMonth = expectedMarketingDate.startOf('month').toISODate();

				if (acc[marketMonth] == null) {
					acc[marketMonth] = 0;
				}

				acc[marketMonth] += production?.sum?.quantity ?? 0;

				return acc;
			}, {})
		).map(([date, quantity]) => ({ date, quantity }));
		return monthlyProductionSwine;
	}

	get feedIngredients(): FeedIngredient[] {
		const ingredientsMap =
			this.model.getFeedIngredientVolumes.data?.FeedIngredientConsumedAndPurchasedVolumes.reduce<Record<string, FeedIngredient>>(
				(acc, volumes) => {
					const ingredientId = volumes.FeedIngredient.id;

					if (!acc[ingredientId]) {
						acc[ingredientId] = volumes.FeedIngredient;
					}
					return acc;
				},
				{}
			) ?? [];

		return Object.values(ingredientsMap).sortBy('name');
	}

	get feedExpenseCategories(): (FeedIngredient | LedgerFeedCategory)[] {
		const feedExpensesData = this.model.getLivestockFeedExpenses?.data;
		const ledgerFeedCategories = feedExpensesData?.LedgerFeedCategories || [];
		const feedIngredients = this.feedIngredients;

		const result: (FeedIngredient | LedgerFeedCategory)[] = [];

		if (feedIngredients.length > 0) {
			result.push(...feedIngredients);
		}

		if (ledgerFeedCategories.length > 0) {
			result.push(...ledgerFeedCategories);
		}

		return result;
	}

	get feedIngredientUsages() {
		return this.model.getFeedIngredientVolumes?.data?.FeedIngredientConsumedAndPurchasedVolumes ?? [];
	}

	get ledgerFeedEntries() {
		return this.model.getLivestockFeedExpenses?.data?.LedgerFeedEntries ?? [];
	}

	get ingredientUsageAndFeedEntries() {
		const feedIngredientUsages = this.feedIngredientUsages;
		const ledgerFeedEntries = this.ledgerFeedEntries;
		const result = [];

		if (feedIngredientUsages.length > 0) {
			result.push(...feedIngredientUsages);
		}

		// Swine producers do not have ledger feed entries
		if (!this.isSwineProducer && ledgerFeedEntries.length > 0) {
			result.push(...ledgerFeedEntries);
		}

		return result;
	}

	get columns(): TableColumn[] {
		const feedEntries: TableColumn[] = this.feedExpenseCategories.map((feedCategory) => {
			const feedId = feedCategory.id;
			const isFixedCost = 'calculationType' in feedCategory;

			const perCWTSubcolumns = [
				{
					id: uuid(feedId + 'Per CWT', this.uuidNamespace),
					name: 'Per CWT',
					textAlign: 'center',
					width: 110,
					valuePath: camelize(feedId + ' Forecasted Cwt'),
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
						...{ footerContent: '(Avg)', footerContentClasses: 'font-sans' },
					},
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: uuid(feedId + 'Total', this.uuidNamespace),
					name: 'Total',
					textAlign: 'center',
					width: 120,
					valuePath: camelize(feedId + ' Forecasted Total'),
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isSortable: false,
					isTotaled: true,
					isFixed: '',
					isVisible: true,
				},
			];

			if (isFixedCost) {
				const isPerCWT = feedCategory.calculationType === 'DairyCwt';
				let subcolumns;
				let columnTitle = feedCategory.name;
				const fixedCostTitle = ' (Fixed Cost)';

				switch (true) {
					case isPerCWT:
						subcolumns = perCWTSubcolumns;
						columnTitle += fixedCostTitle;
						break;
					default:
						columnTitle += fixedCostTitle;
						break;
				}

				return {
					id: uuid(feedId, this.uuidNamespace),
					valuePath: camelize(feedId + ' Forecasted'),
					name: columnTitle,
					cellComponent: CellComponents.IntlNumberFormat,
					width: 160,
					textAlign: 'center',
					isSortable: false,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: '',
					isVisible: true,
					isTotaled: true,
					subcolumns,
				};
			} else {
				const ingredientTitle = ' (Ingredient)';
				const columnTitle = feedCategory.name + ingredientTitle;

				return {
					id: uuid(feedId, this.uuidNamespace),
					valuePath: camelize(feedId + ' Forecasted'),
					name: columnTitle,
					cellComponent: CellComponents.IntlNumberFormat,
					headerLinkRoute: this.ingredientDetailRoutePath,
					headerLinkModel: feedId,
					width: 160,
					textAlign: 'center',
					isSortable: false,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: '',
					isVisible: true,
					isTotaled: true,
				};
			}
		});

		const totalsSubcolumns = [
			{
				id: '9d0c246c-2875-4d21-af80-2bc7ea8cf966',
				name: 'Total',
				valuePath: 'forecastedTotal',
				textAlign: 'center',
				width: 125,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isSortable: false,
				isTotaled: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9ad430aa-da4b-451d-a526-f8a8b2bea2cb',
				name: 'Per CWT',
				valuePath: 'forecastedTotalCwt',
				textAlign: 'center',
				width: 125,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					...{ footerContent: '(Avg)', footerContentClasses: 'font-sans' },
				},
				isSortable: false,
				isTotaled: true,
				isFixed: '',
				isVisible: true,
			},
		];

		return [
			{
				id: '35a1ddf2-183b-4e08-8d35-fe628d5a881e',
				name: 'Month',
				valuePath: 'month',
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				isReorderable: false,
				cellComponent: CellComponents.MonthFormat,
				isFixed: 'left',
				isVisible: true,
			},
			...feedEntries,
			{
				id: 'bcbaf1aa-c3d3-4c7d-8015-42b0e0d6502e',
				name: 'Total',
				minWidth: 150,
				textAlign: 'center',
				isSortable: false,
				isReorderable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: 'right',
				isVisible: true,
				isTotaled: true,
				subcolumns: totalsSubcolumns,
			},
		];
	}

	queryParams = ['startDate', 'endDate'];

	getDateForEntry(entry: AggregateEntryType[number]) {
		if ('year' in entry && 'month' in entry && typeof entry.year === 'number' && typeof entry.month === 'number') {
			return DateTime.local(entry.year, entry.month).toISODate();
		} else if ('monthStartDate' in entry) {
			return entry.monthStartDate ?? null;
		}
		return null;
	}

	initializeRows = (entries: AggregateEntryType | undefined, map: Map<string, AggregateLedgerRow>): void => {
		entries &&
			entries.forEach((entry) => {
				const date = this.getDateForEntry(entry);

				if (date && !map.get(date)) {
					map.set(date, { month: date, id: date });
				}
			});
	};

	calculateDynamicTotalValues = (
		row: Record<string, any>,
		monthlyProduction: MonthlySwineProduction[] | AggregateActualMilkProductionDTO[] | AggregateForecastedMilkProductionByMonthDTO[],
		date: string,
		totalDollarAmount: number,
		totalValuePath: string
	) => {
		let production = 0;
		let numberOfAnimals = 0;

		monthlyProduction.forEach((productionMonth) => {
			if (productionMonth.date !== date) return;
			if (this.isSwineProducer && 'quantity' in productionMonth) {
				const quantity = productionMonth.quantity ?? 0;
				// Convert number of pigs to lbs
				production = quantity * this.averageFinishWeightInLbs;
				numberOfAnimals = quantity;
			} else if (
				'__typename' in productionMonth &&
				(productionMonth.__typename === 'AggregateActualMilkProductionDTO' ||
					productionMonth.__typename === 'AggregateForecastedMilkProductionByMonthDTO')
			) {
				production = productionMonth?.sum?.grossProduction ?? 0;
				numberOfAnimals = productionMonth?.sum?.numberOfCows ?? 0;
			}
		});

		row[totalValuePath + 'Cwt'] = production ? totalDollarAmount / (production / 100) : 0;
		row[totalValuePath + 'Head'] = numberOfAnimals ? totalDollarAmount / numberOfAnimals : 0;
	};

	populateRows = (
		entries: AggregateEntryType | undefined,
		monthlyProductionForecasted: AggregateForecastedMilkProductionByMonthDTO[],
		monthlyProductionActual: AggregateActualMilkProductionDTO[],
		monthlyProductionSwine: MonthlySwineProduction[],
		map: Map<string, AggregateLedgerRow>
	): void => {
		if (!entries) return;

		entries.forEach((entry) => {
			const isFeedIngredient = 'feedIngredientId' in entry;
			const isLedgerEntry = 'LedgerCategory' in entry;
			const date = this.getDateForEntry(entry);
			if (!date) return;

			let row = map.get(date);

			if (!row) {
				row = { month: date, id: date };
				map.set(date, row);
			}

			// Currently we do not support creating actual ingredient usage
			// If that changes, we will need FeedIngredientConsumedAndPurchasedVolumes to return actualConsumptionInTons
			// For now, I am assuming that all FeedIngredientConsumedAndPurchasedVolumes are forecasted
			// - Preston Cobb

			const isActual = 'type' in entry && entry.type === TypeOfLedgerEntry.Actual;
			const productionSource = this.isSwineProducer
				? monthlyProductionSwine
				: isActual
				? monthlyProductionActual
				: monthlyProductionForecasted;
			const valueType = isActual ? 'Actual' : 'Forecasted';
			const generateKey = (prefix: string, type: string) => camelize(`${prefix} ${type}`);

			if (isFeedIngredient) {
				const categoryId = entry?.feedIngredientId;
				const forecastedValuePath = `${camelize(categoryId + ' Forecasted')}`;

				const ingredient = this.feedIngredients.find((v) => v.id === categoryId);
				if (!ingredient) return;

				const marketPrice = this.getMarketPriceForIngredient(ingredient, date);

				if (marketPrice === null) {
					return;
				}

				const outstandingTons = (entry.forecastedConsumptionInTons ?? 0) - (entry.purchasedInTons ?? 0);
				const outstandingPricingUnits = outstandingTons > 0 ? convertTonsToPricingUnit(ingredient, outstandingTons) : 0;
				const forecastedTotal = (entry.totalPurchasedCostInUsd ?? 0) + marketPrice * outstandingPricingUnits;

				const forecastedTotalValuePath = 'forecastedTotal';

				row[forecastedValuePath] = forecastedTotal;
				if (typeof row[forecastedTotalValuePath] === 'number') {
					row[forecastedTotalValuePath] += forecastedTotal;
				} else {
					row[forecastedTotalValuePath] = forecastedTotal;
				}

				this.calculateDynamicTotalValues(row, productionSource, date, row[forecastedTotalValuePath], forecastedTotalValuePath);
			} else if (isLedgerEntry) {
				const categoryId = entry.LedgerCategory?.id;
				if (categoryId !== undefined && categoryId !== null) {
					// individual entry column/subcolumns
					const valuePath = generateKey(categoryId, valueType);
					const cwtValuePath = generateKey(categoryId, `${valueType} Cwt`);
					const headValuePath = generateKey(categoryId, `${valueType} Head`);
					const dynamicTotalValuePath = generateKey(categoryId, `${valueType} Total`);
					// stickied Total column
					const totalValuePath = `${valueType.toLowerCase()}Total`;

					// item per cwt/per head amount
					const dynamicAmount = entry?.avg?.amount;
					// item total $ amount
					const entryTotalAmount = entry?.sum?.calculatedAmount;
					const calculationType = entry?.LedgerCategory?.calculationType;

					// update item subcolumn values
					if (calculationType === 'DairyCwt') {
						if (dynamicAmount !== null && dynamicAmount !== undefined) {
							row[cwtValuePath] = dynamicAmount;
						}
						if (entryTotalAmount !== null && entryTotalAmount !== undefined) {
							row[dynamicTotalValuePath] = entryTotalAmount;
						}
					} else if (calculationType === 'DairyHead') {
						if (dynamicAmount !== null && dynamicAmount !== undefined) {
							row[headValuePath] = dynamicAmount;
						}
						if (entryTotalAmount !== null && entryTotalAmount !== undefined) {
							row[dynamicTotalValuePath] = entryTotalAmount;
						}
					} else {
						if (entryTotalAmount !== null && entryTotalAmount !== undefined) {
							row[valuePath] = entryTotalAmount;
						}
					}

					// update stickied total row value
					if (typeof row[totalValuePath] === 'number') {
						if (entryTotalAmount !== null && entryTotalAmount !== undefined) {
							(row[totalValuePath] as number) += entryTotalAmount;
						}
					} else {
						if (entryTotalAmount !== null && entryTotalAmount !== undefined) {
							row[totalValuePath] = entryTotalAmount;
						}
					}

					const totalDollarAmount = row[totalValuePath] as number;

					this.calculateDynamicTotalValues(row, productionSource, date, totalDollarAmount, totalValuePath);
				}
			}
		});
	};

	fillMissingMonths = (startDate: string | null, endDate: string | null, map: Map<string, AggregateLedgerRow>): void => {
		const interval = Interval.fromISO(`${startDate}/${endDate}`);
		const currentMonths: { [key: string]: number | undefined } = {};

		for (const currentMonth of map.keys()) {
			const date = DateTime.fromISO(currentMonth).startOf('month').toISODate();
			currentMonths[date] = 1;
		}

		for (const date of months(interval)) {
			if (!currentMonths[date.toISODate()]) {
				map.set(date.toISODate(), { month: date.toISODate(), id: date.toISODate() });
			}
		}
	};

	get data(): AggregateLedgerRow[] {
		const map = new Map<string, AggregateLedgerRow>();
		const data = this.model.getLivestockFeedExpenses.data;
		const aggregateEntries = this.ingredientUsageAndFeedEntries;
		const monthlyProductionForecasted = data?.AggregateForecastedMilkProductionByMonths ?? [];
		const monthlyProductionActual = data?.AggregateActualMilkProduction ?? [];
		const monthlyProductionSwine = this.monthlySwineProduction;

		this.initializeRows(aggregateEntries, map);
		this.populateRows(aggregateEntries, monthlyProductionForecasted, monthlyProductionActual, monthlyProductionSwine, map);
		this.fillMissingMonths(this.startDate, this.endDate, map);

		return Array.from(map.values()).sortBy('month');
	}

	get monthlyProductionForecasted() {
		return this.model.getLivestockFeedExpenses.data?.AggregateForecastedMilkProductionByMonths ?? [];
	}

	get monthlyProductionActual() {
		return this.model.getLivestockFeedExpenses.data?.AggregateActualMilkProduction ?? [];
	}

	get columnTotals() {
		const totals: { [key: string]: number } = {};

		const actualTotalValuePath = 'actualTotal';
		const actualTotalCwtValuePath = 'actualTotalCwt';
		const actualTotalHeadValuePath = 'actualTotalHead';
		const forecastedTotalValuePath = 'forecastedTotal';
		const forecastedTotalCwtValuePath = 'forecastedTotalCwt';
		const forecastedTotalHeadValuePath = 'forecastedTotalHead';

		const categoryCwtValues: { [key: string]: number[] } = {};
		const categoryHeadValues: { [key: string]: number[] } = {};
		const totalRowTotalValues: {
			[actualTotalValuePath]: number | null;
			[actualTotalCwtValuePath]: number[];
			[actualTotalHeadValuePath]: number[];
			[forecastedTotalValuePath]: number | null;
			[forecastedTotalCwtValuePath]: number[];
			[forecastedTotalHeadValuePath]: number[];
		} = {
			[actualTotalValuePath]: null,
			[actualTotalCwtValuePath]: [],
			[actualTotalHeadValuePath]: [],
			[forecastedTotalValuePath]: null,
			[forecastedTotalCwtValuePath]: [],
			[forecastedTotalHeadValuePath]: [],
		};

		this.data.forEach((v) => {
			if (typeof v[actualTotalValuePath] === 'number') {
				if (!totalRowTotalValues[actualTotalValuePath]) {
					totalRowTotalValues[actualTotalValuePath] = v[actualTotalValuePath];
				} else {
					totalRowTotalValues[actualTotalValuePath] += v[actualTotalValuePath];
				}
			}

			if (typeof v[forecastedTotalValuePath] === 'number') {
				if (!totalRowTotalValues[forecastedTotalValuePath]) {
					totalRowTotalValues[forecastedTotalValuePath] = v[forecastedTotalValuePath];
				} else {
					totalRowTotalValues[forecastedTotalValuePath] += v[forecastedTotalValuePath];
				}
			}

			if (typeof v[actualTotalCwtValuePath] === 'number' && v[actualTotalCwtValuePath] != null) {
				totalRowTotalValues[actualTotalCwtValuePath].push(v[actualTotalCwtValuePath]);
			}

			if (typeof v[actualTotalHeadValuePath] === 'number' && v[actualTotalHeadValuePath] != null) {
				totalRowTotalValues[actualTotalHeadValuePath].push(v[actualTotalHeadValuePath]);
			}

			if (typeof v[forecastedTotalCwtValuePath] === 'number' && v[forecastedTotalCwtValuePath] != null) {
				totalRowTotalValues[forecastedTotalCwtValuePath].push(v[forecastedTotalCwtValuePath]);
			}

			if (typeof v[forecastedTotalHeadValuePath] === 'number' && v[forecastedTotalHeadValuePath] != null) {
				totalRowTotalValues[forecastedTotalHeadValuePath].push(v[forecastedTotalHeadValuePath]);
			}
		});

		// Add Ledger Feed Entry Totals
		this.ledgerFeedEntries?.forEach((ledgerEntryTotal) => {
			const isPerCWT = ledgerEntryTotal?.LedgerCategory?.calculationType === 'DairyCwt';
			const isPerHead = ledgerEntryTotal?.LedgerCategory?.calculationType === 'DairyHead';
			const isStatic = ledgerEntryTotal?.LedgerCategory?.calculationType === null;
			const typePath = ledgerEntryTotal.type === TypeOfLedgerEntry.Actual ? ' Actual Total' : ' Forecasted Total';
			const typePathCwt = ledgerEntryTotal.type === TypeOfLedgerEntry.Actual ? ' Actual Cwt' : ' Forecasted Cwt';
			const typePathHead = ledgerEntryTotal.type === TypeOfLedgerEntry.Actual ? ' Actual Head' : ' Forecasted Head';

			if (isPerCWT) {
				const cwtTotalSumValuePathByType = `${camelize(ledgerEntryTotal.LedgerCategory?.id + typePath)}`;
				const cwtSumValuePathByType = `${camelize(ledgerEntryTotal.LedgerCategory?.id + typePathCwt)}`;

				totals[cwtTotalSumValuePathByType] = (totals[cwtTotalSumValuePathByType] || 0) + (ledgerEntryTotal.sum.calculatedAmount ?? 0);

				if (ledgerEntryTotal.sum.amount != null) {
					if (categoryCwtValues[cwtSumValuePathByType]) {
						categoryCwtValues[cwtSumValuePathByType].push(ledgerEntryTotal.sum.amount);
					} else {
						categoryCwtValues[cwtSumValuePathByType] = [ledgerEntryTotal.sum.amount];
					}
				}
			} else if (isPerHead) {
				const headTotalSumValuePathByType = `${camelize(ledgerEntryTotal.LedgerCategory?.id + typePath)}`;
				const headSumValuePathByType = `${camelize(ledgerEntryTotal.LedgerCategory?.id + typePathHead)}`;

				totals[headTotalSumValuePathByType] = (totals[headTotalSumValuePathByType] || 0) + (ledgerEntryTotal.sum.calculatedAmount ?? 0);

				if (ledgerEntryTotal.sum.amount != null) {
					if (categoryHeadValues[headSumValuePathByType]) {
						categoryHeadValues[headSumValuePathByType].push(ledgerEntryTotal.sum.amount);
					} else {
						categoryHeadValues[headSumValuePathByType] = [ledgerEntryTotal.sum.amount];
					}
				}
			} else if (isStatic) {
				const entryId = ledgerEntryTotal?.LedgerCategory?.id;
				const entryType = ledgerEntryTotal.type;
				const totalValuePath = `${camelize(entryId + ' ' + entryType)}`;
				const amount = ledgerEntryTotal.sum.amount;

				totals[totalValuePath] = (totals[totalValuePath] || 0) + (amount ?? 0);
			}
		});

		// Add Feed Ingredient Totals
		const feedIngredientFooterTotals = this.generateFeedIngredientFooterTotals();

		this.feedIngredients.forEach((ingredient) => {
			const entryId = ingredient.id;
			const entryType = 'Forecasted';
			const totalValuePath = `${camelize(entryId + ' ' + entryType)}`;
			const amount = feedIngredientFooterTotals[totalValuePath];

			totals[totalValuePath] = amount ?? 0;
		});

		Object.keys(categoryCwtValues).forEach((key) => {
			totals[key] = categoryCwtValues[key].reduce((a, b) => a + b, 0) / categoryCwtValues[key].length;
		});

		Object.keys(categoryHeadValues).forEach((key) => {
			totals[key] = categoryHeadValues[key].reduce((a, b) => a + b, 0) / categoryHeadValues[key].length;
		});

		totals[actualTotalCwtValuePath] =
			totalRowTotalValues[actualTotalCwtValuePath].reduce((a, b) => a + b, 0) / totalRowTotalValues[actualTotalCwtValuePath].length;

		totals[actualTotalHeadValuePath] =
			totalRowTotalValues[actualTotalHeadValuePath].reduce((a, b) => a + b, 0) / totalRowTotalValues[actualTotalHeadValuePath].length;

		totals[forecastedTotalCwtValuePath] =
			totalRowTotalValues[forecastedTotalCwtValuePath].reduce((a, b) => a + b, 0) / totalRowTotalValues[forecastedTotalCwtValuePath].length;

		totals[forecastedTotalHeadValuePath] =
			totalRowTotalValues[forecastedTotalHeadValuePath].reduce((a, b) => a + b, 0) /
			totalRowTotalValues[forecastedTotalHeadValuePath].length;

		totals[actualTotalValuePath] = totalRowTotalValues[actualTotalValuePath] ?? 0;

		totals[forecastedTotalValuePath] = totalRowTotalValues[forecastedTotalValuePath] ?? 0;

		return [totals];
	}

	get csvFileName() {
		return this.model.getLivestockFeedExpenses?.data?.Customer?.name
			? `expense-${classify(this.model.getLivestockFeedExpenses?.data?.Customer?.name)}.csv`
			: null;
	}

	get dateRangeOptions(): DateFilterOption[] {
		return [
			{
				displayName: 'Previous Year',
				startDate: DateTime.local().minus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.local().minus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: `Current Year`,
				startDate: DateTime.local().startOf('year').toISODate(),
				endDate: DateTime.local().endOf('year').toISODate(),
			},
			{
				displayName: `Calendar Year (${DateTime.local().plus({ year: 1 }).year.toString()})`,
				startDate: DateTime.local().plus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.local().plus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: `Calendar Year (${DateTime.local().plus({ year: 2 }).year.toString()})`,
				startDate: DateTime.local().plus({ year: 2 }).startOf('year').toISODate(),
				endDate: DateTime.local().plus({ year: 2 }).endOf('year').toISODate(),
			},
		];
	}

	get dateRangeQueryParam() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get futures() {
		return this.model.getLivestockFeedExpenses.data?.Futures ?? [];
	}

	@action
	setDateRangeQueryParam(value: { startDate?: string; endDate?: string }) {
		this.startDate = value.startDate || null;
		this.endDate = value.endDate || null;
	}

	getMarketPriceForIngredient(feedIngredient: FeedIngredient, month: string) {
		const feedCategory = feedIngredient.FeedCategory;
		const hedgeProductSlug = feedCategory?.HedgeProduct?.slug ?? '';
		const nearestFuture = findNearestFuture(this.futures, hedgeProductSlug, month);

		const displayFactor = nearestFuture?.SymbolGroup?.displayFactor ?? 1;
		const barchartSymbol = nearestFuture?.barchartSymbol;
		let marketPrice: number | null = null;

		if (barchartSymbol) {
			marketPrice = this.marketData.getLatestPrice(barchartSymbol);
		}

		return getFeedIngredientMarketPrice(feedIngredient, marketPrice, displayFactor);
	}

	generateFeedIngredientFooterTotals() {
		const ingredientPaths = this.feedIngredients.map((ingredient) => `${camelize(ingredient.id + ' Forecasted')}`);
		return this.data.reduce<Record<string, number>>((acc, row) => {
			Object.entries(row).forEach(([key, value]) => {
				if (ingredientPaths.includes(key) && typeof value === 'number') {
					acc[key] = (acc[key] || 0) + value;
				}
			});
			return acc;
		}, {});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/livestock-feed/expenses': LivestockFeedExpensesController;
	}
}
