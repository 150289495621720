import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import {
	CustomerEntity,
	LedgerEntryAggregateDTO,
	LedgerEntryFilterDTO,
	TypeOfLedgerCategory,
	LedgerForecastedEntryGroupByDTO,
	Query,
	ActualMilkProductionFilterDTO,
	FeedIngredientForecastedUsageFilterDTO,
	LedgerFeedCategory,
	ForecastedMilkProductionByMonthFilterDTO,
	AggregateLedgerEntryDTO,
	FeedIngredientConsumedAndPurchasedVolumeFilterDTO,
	FeedIngredientConsumedAndPurchasedVolume,
	PaginationInput,
	PaginationDirection,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfLivestockPopulationChangeReason,
} from 'vault-client/types/graphql-types';
import { useQuery, gql } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import Transition from '@ember/routing/transition';
import { ModelFrom } from 'vault-client/utils/type-utils';
import LivestockFeedExpensesController from 'vault-client/controllers/livestock-feed/expenses';
import { service } from '@ember/service';
import MarketDataService from 'vault-client/services/market-data';
import { formatISO, subWeeks, startOfWeek, parseISO } from 'date-fns';

interface modelParams {
	id: string;
	startDate: string;
	endDate: string;
}

const GET_FEED_INGREDIENT_VOLUMES = gql`
	query FeedIngredientConsumedAndPurchasedVolumes(
		$scopeId: String!
		$generateFeedConsumptionAndPurchasedWhere: FeedIngredientConsumedAndPurchasedVolumeFilterDTO!
		$pagination: PaginationInput
	) {
		FeedIngredientConsumedAndPurchasedVolumes(
			where: $generateFeedConsumptionAndPurchasedWhere
			orderBy: { monthStartDate: Asc }
			scopeId: $scopeId
			pagination: $pagination
		) {
			id
			feedIngredientId
			monthStartDate
			purchasedInTons
			totalPurchasedCostInUsd
			forecastedConsumptionInTons
			FeedIngredient {
				id
				versionedConceptSeriesId
				name
				cmePercentageBasis
				cmeUsdBasis
				flatPricePerTon
				FeedCategory {
					id
					name
					defaultFlatPricePerTon
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
	}
`;

const GET_LIVESTOCK_FEED_EXPENSES = gql`
	query AggregateLedgerEntries(
		$calc: LedgerEntryAggregateDTO!
		$groupBy: LedgerEntryGroupByDTO!
		$scopeId: String!
		$where: LedgerEntryFilterDTO
		$swinePurchasesAndProducedWhere: SwineLivestockPopulationChangeFilterDTO!
		$futuresStartDate: String!
		$futuresEndDate: String!
	) {
		Futures(
			where: {
				Product: { slug: { in: ["grain-corn", "grain-soybean-meal"] } }
				isStandardContractSize: { equals: true }
				displayExpiresAt: { gte: $futuresStartDate, lte: $futuresEndDate }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			name
			barchartSymbol
			displayExpiresAt
			SymbolGroup {
				id
				displayFactor
				fractionDigits
			}
			Product {
				id
				slug
				StandardProductLotSpecification {
					id
					pointValue
					lotSize
				}
			}
		}
		LedgerFeedEntries: AggregateLedgerEntries(
			calc: $calc
			groupBy: $groupBy
			where: $where
			orderBy: { month: Asc, year: Asc }
			scopeId: $scopeId
		) {
			sum {
				amount
				calculatedAmount
			}
			avg {
				amount
				calculatedAmount
			}
			type
			month
			year
			LedgerCategory {
				id
				name
				type
				calculationType
			}
		}
		LedgerFeedCategories(scopeId: $scopeId) {
			id
			name
			description
			calculationType
			type
		}
		Customer(id: $scopeId) {
			name
			businessRoles
		}
		AggregateForecastedMilkProductionByMonths(
			calc: { sum: { grossProduction: true, numberOfCows: true } }
			groupBy: { date: true }
			scopeId: $scopeId
		) {
			date
			sum {
				grossProduction
				numberOfCows
			}
		}
		SwinePurchasesAndProduced: AggregateSwineLivestockPopulationChanges(
			calc: { sum: { quantity: true } }
			groupBy: { dob: true }
			where: $swinePurchasesAndProducedWhere
		) {
			dob
			sum {
				quantity
			}
		}
		AggregateActualMilkProduction(
			calc: { sum: { grossProduction: true, numberOfCows: true } }
			groupBy: { firstDateOfMonth: true }
			scopeId: $scopeId
		) {
			firstDateOfMonth
			sum {
				grossProduction
				numberOfCows
			}
		}
	}
`;

type GetFeedIngredientVolumesQuery = {
	__typename?: 'Query';
	FeedIngredientConsumedAndPurchasedVolumes: FeedIngredientConsumedAndPurchasedVolume[];
};

type GetFeedIngredientVolumesQueryVariables = {
	scopeId: string;
	pagination: PaginationInput;
	generateFeedConsumptionAndPurchasedWhere: FeedIngredientConsumedAndPurchasedVolumeFilterDTO;
};

export type GetLivestockFeedExpensesQuery = {
	__typename?: 'Query';
	LedgerFeedCategories: LedgerFeedCategory[];
	LedgerFeedEntries: AggregateLedgerEntryDTO[];
	Customer: CustomerEntity;
	scopeId: string;
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	AggregateActualMilkProduction: Query['AggregateActualMilkProduction'];
	SwinePurchasesAndProduced: Query['AggregateSwineLivestockPopulationChanges'];
	Futures: Query['Futures'];
};

type GetLivestockFeedExpensesQueryVariables = {
	calc: LedgerEntryAggregateDTO;
	groupBy: LedgerForecastedEntryGroupByDTO;
	scopeId: string;
	where: LedgerEntryFilterDTO;
	swinePurchasesAndProducedWhere: SwineLivestockPopulationChangeFilterDTO;
	forecastedProductionWhere: ForecastedMilkProductionByMonthFilterDTO;
	futuresStartDate: string;
	futuresEndDate: string;
};
export default class LiveStockExpensesRoute extends Route {
	templateName = 'livestock-feed/expenses';
	apiLimit = 2000;

	groupBy: LedgerForecastedEntryGroupByDTO = {
		categoryId: true,
		type: true,
		date: true,
		LedgerCategory: {
			name: true,
			id: true,
			calculationType: true,
		},
		month: true,
		year: true,
	};

	calc: LedgerEntryAggregateDTO = {
		sum: {
			amount: true,
			calculatedAmount: true,
		},
		avg: {
			amount: true,
			calculatedAmount: true,
		},
	};

	where: LedgerEntryFilterDTO = {
		LedgerCategory: {
			type: {
				equals: TypeOfLedgerCategory.Feed,
			},
		},
	};

	@service declare marketData: MarketDataService;

	@tracked variables: GetLivestockFeedExpensesQueryVariables = {
		calc: this.calc,
		groupBy: this.groupBy,
		scopeId: '',
		where: this.generateWhere(null, null),
		forecastedProductionWhere: this.generateForecastedProductionWhere(null, null),
		swinePurchasesAndProducedWhere: this.generateSwineSalesPurchasesAndProducedWhere('', '', '', 0),
		futuresStartDate: '',
		futuresEndDate: '',
	};

	@tracked feedIngredientVolumeArgs: GetFeedIngredientVolumesQueryVariables = {
		scopeId: '',
		pagination: {
			direction: PaginationDirection.NextPageFromKeyset,
			previousRowId: null,
			take: this.apiLimit,
		},
		generateFeedConsumptionAndPurchasedWhere: this.generateFeedConsumptionAndPurchasedWhere(
			DateTime.local().startOf('year').toISODate(),
			DateTime.local().endOf('year').toISODate(),
		),
	};

	getLivestockFeedExpenses = useQuery<GetLivestockFeedExpensesQuery, GetLivestockFeedExpensesQueryVariables>(this, () => [
		GET_LIVESTOCK_FEED_EXPENSES,
		{
			variables: this.variables,
		},
	]);

	getFeedIngredientVolumes = useQuery<GetFeedIngredientVolumesQuery, GetFeedIngredientVolumesQueryVariables>(this, () => [
		GET_FEED_INGREDIENT_VOLUMES,
		{
			variables: this.feedIngredientVolumeArgs,
		},
	]);

	generateForecastedProductionWhere(startDate: string | null, endDate: string | null): ForecastedMilkProductionByMonthFilterDTO {
		return {
			date: {
				gte: startDate,
				lte: endDate,
			},
		};
	}

	generateActualProductionWhere(startDate: string | null, endDate: string | null): ActualMilkProductionFilterDTO {
		return {
			date: {
				gte: startDate,
				lte: endDate,
			},
		};
	}

	generateWhere(startDate: string | null, endDate: string | null): LedgerEntryFilterDTO {
		const where: LedgerEntryFilterDTO = {
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.Feed,
				},
				AND: [],
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	generateFeedConsumptionAndPurchasedWhere(startDate: string, endDate: string): FeedIngredientConsumedAndPurchasedVolumeFilterDTO {
		return {
			FeedIngredient: { FeedIngredientVersion: { isCurrent: { equals: true } } },
			startDate,
			endDate,
		};
	}

	generateForecastedWhere(startDate: string | null, endDate: string | null): FeedIngredientForecastedUsageFilterDTO {
		const where: FeedIngredientForecastedUsageFilterDTO = {
			FeedIngredient: {
				FeedIngredientVersion: { isCurrent: { equals: true } },
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	generateSwineSalesPurchasesAndProducedWhere(
		customerId: string,
		startDate: string,
		endDate: string,
		averageFinishAgeInWeeks: number,
	): SwineLivestockPopulationChangeFilterDTO {
		return {
			businessId: { equals: customerId },
			reasonType: {
				in: [TypeOfLivestockPopulationChangeReason.Purchase, TypeOfLivestockPopulationChangeReason.Birth],
			},
			dob: {
				gte: formatISO(subWeeks(startOfWeek(parseISO(startDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
					representation: 'date',
				}),
				lte: formatISO(subWeeks(startOfWeek(parseISO(endDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
					representation: 'date',
				}),
			},
		};
	}

	queryParams = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model(params: modelParams) {
		const { business_id } = this.paramsFor('businesses.business') as { business_id: string };
		const businessModel = this.modelFor('businesses.business') as { Customer: CustomerEntity };
		const averageFinishAgeInWeeks = businessModel.Customer?.averageFinishAgeInWeeks;
		const averageFinishWeightInLbs = businessModel.Customer?.averageFinishWeightInLbs;

		this.variables = {
			calc: this.calc,
			groupBy: this.groupBy,
			futuresStartDate: DateTime.fromISO(params.startDate).minus({ quarter: 1 }).toISODate(),
			futuresEndDate: DateTime.fromISO(params.endDate).plus({ quarter: 1 }).toISODate(),
			where: this.generateWhere(params.startDate, params.endDate),
			forecastedProductionWhere: this.generateForecastedProductionWhere(params.startDate, params.endDate),
			swinePurchasesAndProducedWhere: this.generateSwineSalesPurchasesAndProducedWhere(
				business_id,
				params.startDate,
				params.endDate,
				averageFinishAgeInWeeks,
			),
			scopeId: business_id,
		};

		this.feedIngredientVolumeArgs = {
			scopeId: business_id,
			pagination: {
				direction: PaginationDirection.NextPageFromKeyset,
				previousRowId: null,
				take: this.apiLimit,
			},
			generateFeedConsumptionAndPurchasedWhere: this.generateFeedConsumptionAndPurchasedWhere(params.startDate, params.endDate),
		};

		await this.getLivestockFeedExpenses.promise;
		await this.getFeedIngredientVolumes.promise;

		const numVolumesReturned = this.getFeedIngredientVolumes.data?.FeedIngredientConsumedAndPurchasedVolumes.length ?? 0;

		// If we hit the API limit, fetch another level of data. This should be sufficient for most cases.
		if (numVolumesReturned === this.apiLimit) {
			const lastItemId = this.getFeedIngredientVolumes.data?.FeedIngredientConsumedAndPurchasedVolumes[numVolumesReturned - 1]?.id;

			await this.getFeedIngredientVolumes.fetchMore({
				variables: {
					pagination: {
						direction: PaginationDirection.NextPageFromKeyset,
						previousRowId: lastItemId,
						take: this.apiLimit,
					},
				},
			});
		}

		return {
			entityId: business_id,
			getLivestockFeedExpenses: this.getLivestockFeedExpenses,
			getFeedIngredientVolumes: this.getFeedIngredientVolumes,
			averageFinishWeightInLbs,
			averageFinishAgeInWeeks,
		};
	}

	setupController(
		controller: LivestockFeedExpensesController,
		model: ModelFrom<LiveStockExpensesRoute>,
		transition: Transition<unknown>,
	): void {
		super.setupController(controller, model, transition);

		const registeredFutures =
			model.getLivestockFeedExpenses.data?.Futures.reduce<string[]>((acc, future) => {
				const barchartSymbol = future.barchartSymbol;

				if (barchartSymbol) {
					this.marketData.register(barchartSymbol);
					acc.push(barchartSymbol);
				}

				return acc;
			}, []) ?? [];

		controller.registeredFutures = registeredFutures;
	}

	resetController(controller: LivestockFeedExpensesController) {
		controller.registeredFutures.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});

		controller.registeredFutures = [];
	}
}
