import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import BusinessesBusinessLivestockFeedOverviewDetailRoute from 'vault-client/routes/businesses/business/livestock-feed-overview-detail';
import { TypeOfInstrument, TypeOfOption } from 'vault-client/types/graphql-types';
import { FeedIngredientBase } from 'vault-client/types/vault-client';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { generateFeedBoughtHedgedMonths } from 'vault-client/utils/generate-feed-bought-hedged-months';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class LivestockFeedOverviewDetailController extends Controller {
	@tracked startDate: string = DateTime.now().set({ month: 10 }).startOf('month').minus({ year: 1 }).toISODate();
	@tracked endDate: string = DateTime.now().set({ month: 9 }).endOf('month').toISODate();

	declare model: ModelFrom<BusinessesBusinessLivestockFeedOverviewDetailRoute>;
	feedUsageRoutePath = '';
	feedOverviewRoutePath = '';

	queryParams = ['startDate', 'endDate'];

	id = guidFor(this);

	timePeriodOptions: UiDateFilterOption[] = [
		{
			displayName: `Feed Year ${DateTime.now().minus({ year: 1 }).year}`,
			startDate: DateTime.now().set({ month: 10 }).minus({ year: 2 }).startOf('month').toISODate(),
			endDate: DateTime.now().set({ month: 9 }).minus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: `Feed Year ${DateTime.now().year}`,
			startDate: DateTime.now().set({ month: 10 }).startOf('month').minus({ year: 1 }).toISODate(),
			endDate: DateTime.now().set({ month: 9 }).endOf('month').toISODate(),
		},
		{
			displayName: `Feed Year ${DateTime.now().plus({ year: 1 }).year}`,
			startDate: DateTime.now().set({ month: 10 }).startOf('month').toISODate(),
			endDate: DateTime.now().set({ month: 9 }).plus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	];

	get chartId() {
		return `feed-details-${this.ingredientBase}-chart-${this.id}`;
	}

	get pageTitle() {
		switch (this.ingredientBase) {
			case FeedIngredientBase.Corn:
				return 'Corn Based Ingredients';
			case FeedIngredientBase.SoybeanMeal:
				return 'Soybean Meal Based Ingredients';
			default:
				return 'Other Ingredients';
		}
	}

	get hedgedProductId() {
		return this.model.getFeedDetails.data?.Products.firstObject?.id ?? null;
	}

	get productSlug() {
		switch (this.ingredientBase) {
			case FeedIngredientBase.Corn:
				return 'grain-corn';
			case FeedIngredientBase.SoybeanMeal:
				return 'grain-soybean-meal';
			default:
				return '';
		}
	}

	get usageFound() {
		return this.feedBoughtHedgedMonths.filter((month) => month.usage).length > 0;
	}

	get currentTimePeriodOption() {
		return this.timePeriodOptions.find((option) => option.startDate === this.startDate && option.endDate === this.endDate);
	}

	get ingredientBase(): FeedIngredientBase {
		return this.model.ingredientBase;
	}

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '60628f8d-79df-4cef-9879-ca7faf2ed9cd',
				name: 'Month',
				textAlign: 'left',
				cellComponent: CellComponents.MonthFormat,
				valuePath: 'date',
				isSortable: false,
				isResizable: true,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'b7b68e98-ed8b-46ca-9273-7d316231360f',
				name: 'Usage (DMI Tons)',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				valuePath: 'usageInTons',
				isSortable: false,
				isResizable: true,
				isVisible: true,
				isFixed: '',
			},
			// {
			// 	id: 'cb6d90c4-f8a1-45c1-82cb-bd33e05efde2',
			// 	name: '% Bought',
			// 	textAlign: 'right',
			// 	cellComponent: CellComponents.IntlNumberFormat,
			// 	componentArgs: {
			// 		style: 'percent',
			// 		minimumFractionDigits: 0,
			// 		maximumFractionDigits: 0,
			// 	},
			// 	valuePath: 'boughtPercent',
			// 	isSortable: false,
			// 	isResizable: true,
			// 	isVisible: true,
			// 	isFixed: '',
			// },
		];

		const soyAndCornBasedColumns: TableColumn[] = [
			// {
			// 	id: '1138ec01-35b6-4656-9a02-45444785f86b',
			// 	name: '% Basis Locked',
			// 	textAlign: 'right',
			// 	cellComponent: CellComponents.IntlNumberFormat,
			// 	componentArgs: {
			// 		style: 'percent',
			// 		minimumFractionDigits: 0,
			// 		maximumFractionDigits: 0,
			// 	},
			// 	valuePath: 'basisLockedPercent',
			// 	isSortable: false,
			// 	isResizable: true,
			// 	isVisible: true,
			// 	isFixed: '',
			// },
			// {
			// 	id: 'f936e35e-2676-468d-81e1-72bb65aa4c28',
			// 	name: '% Future Locked',
			// 	textAlign: 'right',
			// 	cellComponent: CellComponents.IntlNumberFormat,
			// 	componentArgs: {
			// 		style: 'percent',
			// 		minimumFractionDigits: 0,
			// 		maximumFractionDigits: 0,
			// 	},
			// 	valuePath: 'futureLockedPercent',
			// 	isSortable: false,
			// 	isResizable: true,
			// 	isVisible: true,
			// 	isFixed: '',
			// },
			{
				id: '3acb390b-7159-4195-a33a-88b408ced0da',
				name: '% Futures Hedged',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				linkRoute: 'businesses.business.positions',
				linkQuery: {
					instrumentType: {
						staticValue: TypeOfInstrument.Future,
					},
					monthDisplayExpiresAtStartDate: {
						rowKey: 'monthStartDate',
					},
					monthDisplayExpiresAtEndDate: {
						rowKey: 'monthEndDate',
					},
					productId: {
						staticValue: this.hedgedProductId,
					},
				},
				valuePath: 'futuresHedgedPercent',
				isSortable: false,
				isResizable: true,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '11d52fcf-33a1-4c9f-b17c-3fd2f6a3a278',
				name: '% Calls Hedged',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				linkRoute: 'businesses.business.positions',
				linkQuery: {
					instrumentType: {
						staticValue: TypeOfInstrument.Option,
					},
					optionType: {
						staticValue: TypeOfOption.Call,
					},
					monthDisplayExpiresAtStartDate: {
						rowKey: 'monthStartDate',
					},
					monthDisplayExpiresAtEndDate: {
						rowKey: 'monthEndDate',
					},
					productId: {
						staticValue: this.hedgedProductId,
					},
				},
				valuePath: 'callsHedgedPercent',
				isSortable: false,
				isResizable: true,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'f41035b7-4785-4ffb-a808-c9bd37ed4059',
				name: '% Unhedged',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				valuePath: 'unhedgedPercent',
				isSortable: false,
				isResizable: true,
				isVisible: true,
				isFixed: '',
			},
		];

		if (this.ingredientBase === FeedIngredientBase.Corn || this.ingredientBase === FeedIngredientBase.SoybeanMeal) {
			return [...baseColumns, ...soyAndCornBasedColumns];
		} else {
			return baseColumns;
		}
	}

	get feedBoughtHedgedMonths() {
		return generateFeedBoughtHedgedMonths(
			this.productSlug,
			this.startDate,
			this.endDate,
			this.model.getFeedDetails.data?.AggregateForecastedSwineLivestockFeedUsages ?? [],
			this.model.getFeedDetails.data?.AggregateAllocatedForecastedHedgedAndCappedVolumes ?? []
		);
	}

	get boughtVsHedgedLegendId() {
		return `chart-legend-${this.id}`;
	}

	get boughtVsHedgedCardHeader() {
		switch (this.ingredientBase) {
			case FeedIngredientBase.Corn:
				return 'Corn Based Ingredients';

			case FeedIngredientBase.SoybeanMeal:
				return 'Soybean Meal Based Ingredients';

			default:
				return 'Other Ingredients';
		}
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}
}
