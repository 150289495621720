import Position from './position';
//@ts-ignore
import Big from 'big.js';
import Swaption from './swaption';

export default class SwaptionPosition extends Position {
	get unrealizedAndRealizedPl() {
		const sortedTrades = this.trades?.sortBy('tradeDate');
		const tradesToOffset = sortedTrades?.map((trade: any) => {
			return {
				quantityInContracts: trade.quantityInContracts,
				realizedPl: trade.realizedPl,
				unrealizedPlPerContract: trade.unrealizedPlPerContract,
			};
		});

		const longTradesToOffset = [];
		const shortTradesToOffset = [];

		let currentTrade;
		let currentQuantityInContracts;
		let currentOffset;

		let realizedPl = new Big(0);
		let unrealizedPl = new Big(0);

		let currentOffsetQuantity;
		let currentQuantityInContractsAbs;

		for (let i = 0; tradesToOffset && i < tradesToOffset.length; i++) {
			currentTrade = tradesToOffset[i];

			// Add premium and any market P/L from expired instruments to realizedPl.
			realizedPl = realizedPl.plus(currentTrade.realizedPl);

			currentQuantityInContracts = currentTrade.quantityInContracts;

			while (currentQuantityInContracts != 0) {
				// A long trade.
				if (currentQuantityInContracts > 0) {
					// Don't have a possible offset.
					if (shortTradesToOffset.length == 0) {
						// Make sure trade has remaining contracts.
						currentTrade.quantityInContracts = currentQuantityInContracts;
						longTradesToOffset.push(currentTrade);
						currentQuantityInContracts = 0;
						continue;
					}

					currentOffset = shortTradesToOffset[0];

					currentOffsetQuantity = new Big(currentOffset.quantityInContracts).abs();
					currentQuantityInContractsAbs = new Big(currentQuantityInContracts).abs();

					// Use all of the current offset and pop it.
					if (currentOffsetQuantity.lte(currentQuantityInContractsAbs)) {
						realizedPl = realizedPl.plus(currentOffsetQuantity.times(currentOffset.unrealizedPlPerContract));
						realizedPl = realizedPl.plus(currentOffsetQuantity.times(currentTrade.unrealizedPlPerContract));
						currentQuantityInContracts = currentQuantityInContracts - Math.abs(currentOffset.quantityInContracts);
						shortTradesToOffset.shift();
						continue;
					}

					if (currentOffsetQuantity.gt(currentQuantityInContractsAbs)) {
						realizedPl = realizedPl.plus(currentQuantityInContractsAbs.times(currentOffset.unrealizedPlPerContract));
						realizedPl = realizedPl.plus(currentQuantityInContractsAbs.times(currentTrade.unrealizedPlPerContract));
						currentOffset.quantityInContracts += currentQuantityInContracts;
						currentQuantityInContracts = 0;
						continue;
					}
				}

				// A short trade.
				if (currentQuantityInContracts < 0) {
					// Don't have a possible offset.
					if (longTradesToOffset.length == 0) {
						currentTrade.quantityInContracts = currentQuantityInContracts;
						shortTradesToOffset.push(currentTrade);
						currentQuantityInContracts = 0;
						continue;
					}

					currentOffset = longTradesToOffset[0];
					currentOffsetQuantity = new Big(currentOffset.quantityInContracts).abs();
					currentQuantityInContractsAbs = new Big(currentQuantityInContracts).abs();

					if (currentOffsetQuantity.lte(currentQuantityInContractsAbs)) {
						realizedPl = realizedPl.plus(currentOffsetQuantity.times(currentOffset.unrealizedPlPerContract));
						realizedPl = realizedPl.plus(currentOffsetQuantity.times(currentTrade.unrealizedPlPerContract));
						currentQuantityInContracts = currentQuantityInContracts + Math.abs(currentOffset.quantityInContracts);
						longTradesToOffset.shift();
						continue;
					}

					if (currentOffsetQuantity.gt(currentQuantityInContractsAbs)) {
						realizedPl = realizedPl.plus(currentQuantityInContractsAbs.times(currentOffset.unrealizedPlPerContract));
						realizedPl = realizedPl.plus(currentQuantityInContractsAbs.times(currentTrade.unrealizedPlPerContract));
						currentOffset.quantityInContracts += currentQuantityInContracts;
						currentQuantityInContracts = 0;
						continue;
					}
				}
			}
		}

		// We have trades that weren't offset
		// So if they have unrealized P/L, it should be used.

		longTradesToOffset.forEach((trade) => {
			unrealizedPl = unrealizedPl.plus(trade.unrealizedPlPerContract.times(trade.quantityInContracts));
		});

		shortTradesToOffset.forEach((trade) => {
			unrealizedPl = unrealizedPl.plus(trade.unrealizedPlPerContract.times(trade.quantityInContracts));
		});

		const floatRealizedPl = parseFloat(realizedPl.toString());
		const floatUnrealizedPl = parseFloat(unrealizedPl.toString());

		return { floatRealizedPl, floatUnrealizedPl };
	}

	get unrealizedPl() {
		return this.unrealizedAndRealizedPl.floatUnrealizedPl;
	}

	get realizedPl() {
		return this.unrealizedAndRealizedPl.floatRealizedPl;
	}

	get marketDataInstrument() {
		const swaptionInstrument = this.Instrument as Swaption;
		return swaptionInstrument.priceInstrument ? swaptionInstrument.priceInstrument : undefined;
	}
}
