import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @feedIngredient}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Feed Ingredient</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to delete this ingredient?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@feedIngredient.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tThis action is irreversible\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.submitting}} {{on 'click' this.removeFeedIngredient}}>Delete Ingredient</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @feedIngredient}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Feed Ingredient</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to delete this ingredient?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@feedIngredient.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tThis action is irreversible\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.submitting}} {{on 'click' this.removeFeedIngredient}}>Delete Ingredient</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-feed-ingredient-modal.hbs","parseOptions":{"srcName":"vault-client/components/delete-feed-ingredient-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';
import { FeedIngredient, Mutation, Mutation_deleteFeedIngredientArgs } from 'vault-client/types/graphql-types';

interface RemoveMemberModalArgs {
	feedIngredient: FeedIngredient | null;
	closeModalFn: Function;
	model: any;
}

const DELETE_FEED_INGREDIENT = gql`
	mutation deleteFeedIngredient($id: String!) {
		deleteFeedIngredient(id: $id) {
			FeedIngredients {
				id
			}
		}
	}
`;

export default class DeleteFeedIngredientModal extends Component<RemoveMemberModalArgs> {
	@tracked member: any;
	@tracked submitting = false;

	deleteFeedIngredient = useMutation<Mutation['deleteFeedIngredient'], Mutation_deleteFeedIngredientArgs>(this, () => [
		DELETE_FEED_INGREDIENT,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'LedgerFeedCategory' });
				cache.evict({ fieldName: 'LedgerFeedCategories' });

				cache.gc();
			},
		},
	]);

	@action
	async removeFeedIngredient() {
		this.submitting = true;
		const { id } = this.args.feedIngredient ?? {};

		if (id) {
			await this.deleteFeedIngredient.mutate({
				id,
			});
		}

		if (this.args.model) {
			await this.args.model.refetch();
		}

		this.closeConfirmation();
		this.submitting = false;
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
