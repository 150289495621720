import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import BusinessesBusinessFeedCostsRoute from 'vault-client/routes/businesses/business/feed-costs';
import { LedgerFeedCategory } from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class FeedCostsController extends Controller {
	@tracked fixedFeedCostCategoryToEdit: LedgerFeedCategory | null = null;
	@tracked fixedFeedCostCategoryToDelete: LedgerFeedCategory | null = null;

	feedUsageRoutePath = '';
	declare model: ModelFrom<BusinessesBusinessFeedCostsRoute>;

	get fixedFeedCostsColumns(): TableColumn[] {
		return [
			{
				id: '2adc71f5-54c5-4ca4-a626-fab4e5dd5b22',
				name: 'Fixed Cost',
				valuePath: 'name',
				textAlign: 'left',
				width: 160,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'de230c44-7e4e-4f8e-9fbe-dc78aa5de624',
				name: 'Unit Type',
				valuePath: 'calculationType',
				textAlign: 'left',
				width: 150,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e70e7628-93c8-4555-a1dd-58456051d48c',
				name: 'Description',
				valuePath: 'description',
				textAlign: 'left',
				width: 500,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1475ea2a-a111-41d5-a1c9-cfacd9a36a07',
				name: '',
				valuePath: 'id',
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Edit',
					fn: this.editFixFeedCategory,
					rightIconHref: '/icons/Edit-Outline.svg#edit-outline',
					rightIconClass: 'icon-interactive-stroke',
				},
				width: 20,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e4078940-9b48-4fe0-b6de-690ff727c291',
				name: '',
				valuePath: 'id',
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.deleteFixFeedCategory,
					rightIconHref: '/icons/Delete-Outline.svg#delete-outline',
					rightIconClass: 'icon-interactive-stroke',
				},
				width: 20,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get fixedFeedCosts() {
		return (this.model.getFixedFeedCosts.data?.LedgerFeedCategories || []).map((category) => ({
			...category,
			calculationType: category?.calculationType || 'Static',
		}));
	}

	get customerId() {
		return this.model.id;
	}

	@action
	editFixFeedCategory(row: LedgerFeedCategory) {
		this.fixedFeedCostCategoryToEdit = this.model.getFixedFeedCosts.data?.LedgerFeedCategories.find((cat) => cat?.id === row.id) ?? null;
	}

	@action
	deleteFixFeedCategory(row: LedgerFeedCategory) {
		this.fixedFeedCostCategoryToDelete = this.model.getFixedFeedCosts.data?.LedgerFeedCategories.find((cat) => cat?.id === row.id) ?? null;
	}

	@action
	onEditFeedCostCategoryClose() {
		this.fixedFeedCostCategoryToEdit = null;
	}

	@action
	onDeleteFeedCostCategoryClose() {
		this.fixedFeedCostCategoryToDelete = null;
	}
}
