import Component from '@glimmer/component';
import { action } from '@ember/object';
import { downloadChartAsCSV } from 'vault-client/utils/chart-utils';
import { tracked } from '@glimmer/tracking';
import { Chart } from 'chart.js';
import { setObjProp } from 'vault-client/utils/general';

interface UiDownloadChartButtonArgs {
	chartId: string;
	disabled?: boolean;
	// Disable download to CSV by default to help meet CME Market Data regulations (Opt In)
	enableCSV?: boolean;
}

export default class UiDownloadChartButton extends Component<UiDownloadChartButtonArgs> {
	@tracked showChartDownloadOptions = false;

	// Phile Plourd requested that the PNG have a 9:6 aspect ratio
	aspectRatioPNG = 9 / 6;
	downloadDevicePixelRatio = 4;

	@action
	closeDropdown() {
		this.showChartDownloadOptions = false;
	}

	@action
	openDropdown() {
		this.showChartDownloadOptions = true;
	}

	@action
	toggleDropdown() {
		if (this.showChartDownloadOptions) {
			this.closeDropdown();
		} else {
			this.openDropdown();
		}
	}

	@action
	downloadChartToPNG() {
		try {
			const canvas = document.getElementById(`${this.args.chartId}`) as HTMLCanvasElement | null;
			const ctx = canvas?.getContext('2d');
			const chart = Chart.getChart(this.args.chartId);

			if (!chart) {
				console.warn(`Could not download chart ${this.args.chartId}. No chart instance found.`);
				return;
			}

			if (!ctx || !canvas) {
				console.warn(`Could not download chart ${this.args.chartId}. No canvas context found.`);
				return;
			}

			const currentDevicePixelRatio = chart.currentDevicePixelRatio;

			const parentElement = canvas.parentElement;

			const currentParentHeight = canvas.parentElement?.style.height ?? '';
			const currentParentWidth = canvas.parentElement?.style.width ?? '';

			ctx.save();

			// Change parentElement width and height to fit aspect ratio requested by Phil Plourd
			if (parentElement) {
				const adjustedWidth = (chart.width < 500 ? 500 : chart.width) / this.aspectRatioPNG;

				parentElement.style.width = adjustedWidth + 'px';
				parentElement.style.height = adjustedWidth / this.aspectRatioPNG + 'px';
			}

			// Increase pixel ratio for better print resolution, regardless of screen resolution
			chart.options.devicePixelRatio = this.downloadDevicePixelRatio;
			// Enable the default legend for the chart
			const defaultLegendOptionValues = this.setLegendOptionsForDownload(chart.options);

			// Apply changes
			chart.resize();

			const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const compositeOperation = ctx.globalCompositeOperation;
			ctx.globalCompositeOperation = 'destination-over';
			ctx.fillStyle = '#FFFFFF';
			ctx?.fillRect(0, 0, canvas.width, canvas.height);
			const dataURL = canvas.toDataURL('image/png');
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.putImageData(data, 0, 0);
			ctx.globalCompositeOperation = compositeOperation;
			const a = document.createElement('a');
			a.href = dataURL;
			a.download = `${this.args.chartId}.png`;
			a.click();
			ctx.restore();

			// Restore aspect ratio changes
			if (parentElement) {
				parentElement.style.height = currentParentHeight;
				parentElement.style.width = currentParentWidth;
			}

			// Restore changed chart properties to original values
			chart.options.devicePixelRatio = currentDevicePixelRatio;

			// Revert legend options
			this.unsetLegendOptionsForDownload(chart.options, defaultLegendOptionValues);

			chart.resize();
		} catch (e) {
			console.error(e);
		}
	}

	@action
	downloadChartToCSV() {
		try {
			downloadChartAsCSV(this.args.chartId, this.args.chartId);
		} catch (e) {
			console.error(e);
		}
	}

	@action
	handleDropdownKeyDown(event: KeyboardEvent): void {
		const eventTarget = event.currentTarget as Element;
		const elements = [...eventTarget.getElementsByClassName('ui-dropdown-item')] as HTMLElement[];
		const length = elements.length - 1;
		let activeItem = elements.findIndex((el) => el.matches(':focus'));

		if (event.key === 'Down' || event.key === 'ArrowDown') {
			event.preventDefault();
			activeItem = activeItem === length ? 0 : ++activeItem;
			elements[activeItem].focus({ preventScroll: false });
		} else if ((event.key === 'Up' || event.key === 'ArrowUp') && activeItem > 0) {
			event.preventDefault();
			elements[--activeItem].focus({ preventScroll: false });
		} else if (event.key === 'Esc' || event.key === 'Escape') {
			event.preventDefault();
			const eventTarget = event.target as HTMLElement;
			eventTarget.blur();
			this.closeDropdown();
		} else if (event.shiftKey && event.key === 'Tab') {
			if (event.shiftKey && activeItem === 0) {
				this.closeDropdown();
			}
		} else if (event.key === 'Tab') {
			if (activeItem === length) {
				this.closeDropdown();
			}
		}
	}

	private setLegendOptionsForDownload(options: Chart['options']) {
		const defaultValues = {
			modifiedLegend: false,
			legend: {
				display: options?.plugins?.legend?.display,
				labels: {
					usePointStyle: options?.plugins?.legend?.labels?.usePointStyle,
					boxWidth: options?.plugins?.legend?.labels?.boxWidth,
					boxHeight: options?.plugins?.legend?.labels?.boxHeight,
					padding: options?.plugins?.legend?.labels?.padding,
					font: options?.plugins?.legend?.labels?.font,
				},
			},
		};

		if (options.plugins?.legend?.display === true) {
			// If the legend is already displayed in the canvas
			// we do not need to make changes
			return defaultValues;
		}

		setObjProp(options, 'plugins.legend.display', true);
		setObjProp(options, 'plugins.legend.labels.usePointStyle', true);
		setObjProp(options, 'plugins.legend.labels.boxWidth', 5);
		setObjProp(options, 'plugins.legend.labels.boxHeight', 5);
		setObjProp(options, 'plugins.legend.labels.padding', 10);
		setObjProp(options, 'plugins.legend.labels.font.size', 10);
		defaultValues.modifiedLegend = true;

		return defaultValues;
	}

	private unsetLegendOptionsForDownload(
		options: Chart['options'],
		defaultValues: ReturnType<UiDownloadChartButton['setLegendOptionsForDownload']>
	) {
		// If setLegendOptionsForDownload did not modify the legend,
		// we do not need to revert the changes
		if (!defaultValues.modifiedLegend) return;

		setObjProp(options, 'plugins.legend.display', defaultValues.legend.display);
		setObjProp(options, 'plugins.legend.labels.usePointStyle', defaultValues.legend.labels.usePointStyle);
		setObjProp(options, 'plugins.legend.labels.boxWidth', defaultValues.legend.labels.boxWidth);
		setObjProp(options, 'plugins.legend.labels.boxHeight', defaultValues.legend.labels.boxHeight);
		setObjProp(options, 'plugins.legend.labels.padding', defaultValues.legend.labels.padding);
		setObjProp(options, 'plugins.legend.labels.font', defaultValues.legend.labels.font);
	}
}
