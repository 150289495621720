import Route from '@ember/routing/route';
import { RouteQueryParam } from '@ember/routing/types';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import FeedIngredientDetailController from 'vault-client/controllers/feed/ingredients/detail';
import MarketDataService from 'vault-client/services/market-data';
import {
	AllocatedForecastedHedgedAndCappedVolumeFilterDTO,
	FeedIngredientFilterDTO,
	FutureFilterDTO,
	Query,
	TypeOfAllocatedHedge,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { FeedIngredientBase } from 'vault-client/types/vault-client';

export type FeedIngredientQueryArgs = {
	id: string;
	feedIngredientsWhere?: FeedIngredientFilterDTO;
};

export type GetFeedIngredientQuery = {
	__typename?: 'Query';
	FeedIngredient: Query['FeedIngredient'];
	FeedIngredients: Query['FeedIngredients'];
	// TODO: Swap this for correct seller info. Only added as temp for wiring up controller
	Sellers: Query['Sellers'];
};

export type GetFeedUsageQueryArgs = {
	versionedConceptSeriesId: string;
	startDate: string;
	endDate: string;
};

export type GetFeedUsageQuery = {
	__typename?: 'Query';
	AggregateFeedIngredientForecastedUsages: Query['AggregateFeedIngredientForecastedUsages'];
};

export type GetHedgedPositionsQueryArgs = {
	forecastedHedgedAndCappedVolumeWhere?: AllocatedForecastedHedgedAndCappedVolumeFilterDTO;
	futuresWhere?: FutureFilterDTO;
};

export type GetHedgedPositionsQuery = {
	__typename?: 'Query';
	AggregateAllocatedForecastedHedgedAndCappedVolumes: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
	Futures: Query['Futures'];
};

export default class FeedIngredientDetailRoute extends Route {
	@service declare marketData: MarketDataService;

	@tracked feedIngredientVariables: FeedIngredientQueryArgs = { id: '' };
	@tracked hedgedPositionsVariables: GetHedgedPositionsQueryArgs = {};
	@tracked feedUsageVariables: GetFeedUsageQueryArgs = { versionedConceptSeriesId: '', startDate: '', endDate: '' };

	templateName = 'feed/ingredients/detail';

	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
		purchasesVendorId: { refreshModel: true },
		openOrderVendorId: { refreshModel: true },
	};

	generateFeedIngredientsWhere(businessId: string | null) {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		where.FeedIngredientVersion = {
			isCurrent: {
				equals: true,
			},
		};

		return where;
	}

	generateFuturesWhere(ingredientBase: FeedIngredientBase, startDate: string, endDate: string) {
		const where: FutureFilterDTO = {};
		where.displayExpiresAt = { gt: startDate, lt: endDate };
		where.isStandardContractSize = { equals: true };
		if (ingredientBase === FeedIngredientBase.Corn) {
			where.Product = { slug: { equals: 'grain-corn' } };
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			where.Product = { slug: { equals: 'grain-soybean-meal' } };
		} else {
			where.Product = { slug: { equals: null } };
		}

		return where;
	}

	generateForecastedHedgedAndCappedVolumeWhere(
		ingredientBase: FeedIngredientBase,
		businessId: string,
		startDate: string,
		endDate: string
	): AllocatedForecastedHedgedAndCappedVolumeFilterDTO {
		let productSlug: string | null = null;

		if (ingredientBase === FeedIngredientBase.Corn) {
			productSlug = 'grain-corn';
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			productSlug = 'grain-soybean-meal';
		}

		const forecastedHedgedAndCappedVolumeWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO = {
			entityId: {
				equals: businessId,
			},
			hedgeType: {
				equals: TypeOfAllocatedHedge.Brokerage,
			},
			instrumentType: {
				in: [TypeOfInstrument.Future, TypeOfInstrument.Option],
			},
			OR: [
				{
					optionType: {
						equals: null,
					},
				},
				{
					optionType: {
						equals: TypeOfOption.Call,
					},
				},
			],
			date: {
				gte: startDate,
				lte: endDate,
			},
		};

		if (productSlug) {
			forecastedHedgedAndCappedVolumeWhere.Product = {
				slug: {
					equals: productSlug,
				},
			};
		} else {
			forecastedHedgedAndCappedVolumeWhere.productId = { equals: null };
		}

		return forecastedHedgedAndCappedVolumeWhere;
	}

	resetController(controller: FeedIngredientDetailController) {
		// Unregister futures when ingredient changes or route is exited
		Object.keys(controller.registeredFutures).forEach((barchartSymbol) => {
			if (this.marketData.getMarketDatum(barchartSymbol)) {
				this.marketData.unregister(barchartSymbol);
			}
		});

		controller.registeredFutures = {};
	}
}
