import Big from 'big.js';
import { FeedIngredient, Future } from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { CreateEditFeedIngredientData } from 'vault-client/components/create-edit-feed-ingredient-form';
import {
	FeedCategory,
	FeedIngredientCreateDTO,
	Mutation,
	Mutation_createFeedIngredientArgs,
	Mutation_updateFeedIngredientArgs,
} from 'vault-client/types/graphql-types';
import { analyticsCustomTracking } from './analytics-tracking';
import { PricingUnit } from './product-slug-to-pricing-unit';

type FeedPriceType = 'Spot Price' | 'CME Basis (USD)' | 'CME Basis (%)';

type DefaultPricing = { feedPriceType: FeedPriceType; value: number | null };

function getFeedIngredientMarketPrice(feedIngredient: FeedIngredient, futurePrice: number | null, displayFactor: number) {
	let spotPrice;
	let cmeBasisPrice;
	let cmeBasisPricePercent;

	if (feedIngredient?.flatPricePerTon) {
		spotPrice = feedIngredient.flatPricePerTon;
	} else if (feedIngredient?.cmeUsdBasis) {
		cmeBasisPrice = feedIngredient.cmeUsdBasis * displayFactor;
	} else if (feedIngredient?.cmePercentageBasis) {
		cmeBasisPricePercent = feedIngredient.cmePercentageBasis;
	} else if (feedIngredient?.FeedCategory?.defaultFlatPricePerTon) {
		spotPrice = feedIngredient.FeedCategory.defaultFlatPricePerTon;
	} else if (feedIngredient?.FeedCategory?.defaultCmeUsdBasis) {
		cmeBasisPrice = feedIngredient.FeedCategory.defaultCmeUsdBasis * displayFactor;
	} else if (feedIngredient?.FeedCategory?.defaultCmePercentageBasis) {
		cmeBasisPricePercent = feedIngredient.FeedCategory.defaultCmePercentageBasis;
	}

	if (spotPrice) return spotPrice;

	if (!futurePrice || !displayFactor) return null;

	if (cmeBasisPrice) {
		return futurePrice * displayFactor + cmeBasisPrice;
	}

	if (cmeBasisPricePercent) {
		return futurePrice * cmeBasisPricePercent * displayFactor;
	}

	return null;
}

function getPricingUnitForFeedIngredient(feedIngredient: FeedIngredient): PricingUnit {
	const isCornIngredient = feedIngredient?.FeedCategory?.HedgeProduct?.slug === 'grain-corn';

	const flatPricePerTon = feedIngredient?.flatPricePerTon;
	const cmeUsdBasis = feedIngredient?.cmeUsdBasis;
	const cmePercentageBasis = feedIngredient?.cmePercentageBasis;

	const defaultFlatPricePerTon = feedIngredient?.FeedCategory?.defaultFlatPricePerTon;

	// Flat priced if flatPricePerTon is set or if no basis is set and defaultFlatPricePerTon is set
	const isFlatPricedPerTon =
		flatPricePerTon !== null || (cmeUsdBasis == null && cmePercentageBasis == null && defaultFlatPricePerTon != null);

	// If the ingredient is flatPricedPerTon, return Tons regardless of hedge product
	if (isFlatPricedPerTon) {
		return 'Ton';
	}

	if (isCornIngredient) {
		return 'Bushel';
	}

	// All ingredients that are not corn are priced per ton
	return 'Ton';
}

function convertTonsToPricingUnit(feedIngredient: FeedIngredient, tons: number) {
	// Convert tons to the relevant pricing unit for the feed ingredient. Ingredient price settings determine the pricing unit.

	const ingredientPricingUnit = getPricingUnitForFeedIngredient(feedIngredient);

	if (ingredientPricingUnit === 'Bushel') {
		return new Big(tons).times(2000).div(56).toNumber(); // Corn can be priced per bushel. 1 bushel = 56 lbs
	} else {
		return tons; // Soybean meal, flat priced corn, and other ingredients are priced per short ton
	}
}

const CREATE_FEED_INGREDIENT_MUTATION = gql`
	mutation CreateFeedIngredient($data: FeedIngredientCreateDTO!) {
		createFeedIngredient(data: $data) {
			FeedIngredients {
				id
				versionedConceptSeriesId
				dryMatterPercent
				name
				FeedCategory {
					id
				}
			}
			Version {
				id
				name
			}
		}
	}
`;

const UPDATE_FEED_INGREDIENT_MUTATION = gql`
	mutation UpdateFeedIngredient($data: FeedIngredientUpdateDTO!, $id: String!) {
		updateFeedIngredient(data: $data, id: $id) {
			FeedIngredients {
				id
				versionedConceptSeriesId
				name
			}
			Version {
				id
				name
			}
		}
	}
`;

async function createFeedIngredient(this: object, variables: Mutation_createFeedIngredientArgs, customerRole?: string) {
	const mutation = useMutation<{ createFeedIngredient: Mutation['createFeedIngredient'] }, Mutation_createFeedIngredientArgs>(this, () => [
		CREATE_FEED_INGREDIENT_MUTATION,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to create feed ingredient: ${variables}`);
			},
			onComplete: () => {
				analyticsCustomTracking('Feed Ingredient Created', { 'Ingredient Added': variables.data.name, 'Business Role': customerRole });
			},
			update: (cache) => {
				cache.evict({ fieldName: 'LedgerFeedCategory' });
				cache.evict({ fieldName: 'LedgerFeedCategories' });
				cache.evict({ fieldName: 'FeedIngredients' });
				cache.evict({ fieldName: 'FeedIngredient' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

async function updateFeedIngredient(this: object, variables: Mutation_updateFeedIngredientArgs, customerRole?: string) {
	const mutation = useMutation<{ updateFeedIngredient: Mutation['updateFeedIngredient'] }, Mutation_updateFeedIngredientArgs>(this, () => [
		UPDATE_FEED_INGREDIENT_MUTATION,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to Edit feed ingredient: ${variables}`);
			},
			onComplete: () => {
				analyticsCustomTracking('Feed Ingredient Edited', { 'Ingredient Edited': variables.data.name, 'Business Role': customerRole });
			},
			update: (cache) => {
				cache.evict({ fieldName: 'LedgerFeedCategory' });
				cache.evict({ fieldName: 'LedgerFeedCategories' });
				cache.evict({ fieldName: 'FeedIngredients' });
				cache.evict({ fieldName: 'FeedIngredient' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

function getDefaultPricing(feedCategory: FeedCategory | null | undefined): DefaultPricing {
	const defaultFlatPricePerTon = feedCategory?.defaultFlatPricePerTon;
	const defaultCmeUsdBasis = feedCategory?.defaultCmeUsdBasis;
	const defaultCmePercentageBasis = feedCategory?.defaultCmePercentageBasis;

	if (defaultFlatPricePerTon != null) {
		return {
			feedPriceType: 'Spot Price',
			value: defaultFlatPricePerTon,
		};
	} else if (defaultCmeUsdBasis != null) {
		return {
			feedPriceType: 'CME Basis (USD)',
			value: defaultCmeUsdBasis,
		};
	} else if (defaultCmePercentageBasis != null) {
		return {
			feedPriceType: 'CME Basis (%)',
			value: defaultCmePercentageBasis,
		};
	}

	return {
		feedPriceType: 'Spot Price',
		value: null,
	};
}

function parseCreateEditFeedIngredientData(feedIngredientData: CreateEditFeedIngredientData) {
	const dmiPercentage = parseFloat(feedIngredientData.dmiPercentage.trim());
	const ingredientName = feedIngredientData.ingredientName.trim();
	const displayFactor = feedIngredientData.feedCategory?.HedgeProduct?.MostCurrentFuture?.SymbolGroup?.displayFactor ?? 1;
	const feedCategoryId = feedIngredientData.feedCategory?.id;

	if (dmiPercentage > 100) {
		throw new Error('DMI Percentage cannot be greater than 100%');
	}

	if (dmiPercentage < 0) {
		throw new Error('DMI Percentage cannot be less than 0%');
	}

	if (!feedCategoryId) {
		throw new Error('Feed Category could not be found');
	}

	const data: FeedIngredientCreateDTO = {
		feedCategoryId,
		dryMatterPercent: dmiPercentage / 100,
		name: ingredientName,
		flatPricePerTon: null,
		cmeUsdBasis: null,
		cmePercentageBasis: null,
	};

	if (feedIngredientData.feedPriceType === 'Spot Price') {
		const parsedFlatPricePerTon = feedIngredientData.flatPricePerTon?.trim().length
			? parseFloat(feedIngredientData.flatPricePerTon.trim())
			: undefined;

		if (parsedFlatPricePerTon && parsedFlatPricePerTon < 0) {
			throw new Error('Spot Price cannot be a negative number');
		}

		data.flatPricePerTon = parsedFlatPricePerTon ?? feedIngredientData.feedCategory?.defaultFlatPricePerTon ?? null;
	} else if (feedIngredientData.feedPriceType === 'CME Basis (USD)') {
		const parsedCmeUsdBasis = feedIngredientData.cmeBasisUsd?.trim().length ? parseFloat(feedIngredientData.cmeBasisUsd.trim()) : undefined;
		const unadjustedBasis = parsedCmeUsdBasis ?? feedIngredientData.feedCategory?.defaultCmeUsdBasis ?? null;

		data.cmeUsdBasis = unadjustedBasis !== null ? unadjustedBasis / displayFactor : null;
	} else if (feedIngredientData.feedPriceType === 'CME Basis (%)') {
		const parsedCmePercentagebasis = feedIngredientData.cmeBasisPercentage?.trim().length
			? parseFloat(feedIngredientData.cmeBasisPercentage.trim()) / 100
			: undefined;

		if (parsedCmePercentagebasis && parsedCmePercentagebasis < 0) {
			throw new Error('CME Basis (%) cannot be a negative number');
		}

		data.cmePercentageBasis = parsedCmePercentagebasis ?? feedIngredientData.feedCategory?.defaultCmePercentageBasis ?? null;
	}

	// cmePercentageBasis cannot be zero, but cmeUsdBasis and flatPricePerTon can
	// Apply default pricing if no price is set
	if (!data.cmePercentageBasis && data.flatPricePerTon === null && data.cmeUsdBasis === null) {
		const defaultPricing = getDefaultPricing(feedIngredientData.feedCategory);
		const defaultPricingValue = defaultPricing.value;

		// Check that a default price value exists. If not, show an error and require user to input a value.
		if (defaultPricingValue === null) {
			throw new Error('Feed Price Type could not be set. Please ensure it is included');
		}

		if (defaultPricing.feedPriceType === 'Spot Price') {
			data.flatPricePerTon = defaultPricingValue;
		} else if (defaultPricing.feedPriceType === 'CME Basis (USD)') {
			data.cmeUsdBasis = defaultPricingValue;
		} else if (defaultPricing.feedPriceType === 'CME Basis (%)') {
			data.cmePercentageBasis = defaultPricingValue;
		}
	}

	if ((data.cmeUsdBasis !== null || data.cmePercentageBasis !== null) && !feedIngredientData.feedCategory?.HedgeProduct) {
		throw new Error('CME Basis can only be set on ingredients whose category has a hedge product');
	}

	return data;
}

function clearCreateEditFeedIngredientData(feedIngredientData: CreateEditFeedIngredientData) {
	feedIngredientData.error = '';
	feedIngredientData.ingredientName = '';
	feedIngredientData.dmiPercentage = '100';
	feedIngredientData.feedPriceType = 'Spot Price';
	feedIngredientData.flatPricePerTon = '';
	feedIngredientData.cmeBasisUsd = '';
	feedIngredientData.cmeBasisPercentage = '';
	feedIngredientData.feedCategory = null;
}

function findNearestFuture(futures: Future[], productSlug: string, month: string) {
	// We sort futures asc by display expires at, allowing a >= check for finding the nearest future
	// If the date is too far in the future to be priced, use the last future
	const relevantFutures = futures.filter((future) => future.Product.slug === productSlug) ?? [];
	const sortedRelevantFutures = relevantFutures.sortBy('displayExpiresAt');
	return sortedRelevantFutures?.find((future) => future.displayExpiresAt >= month) ?? futures?.[futures?.length - 1] ?? null;
}

export {
	createFeedIngredient,
	updateFeedIngredient,
	getDefaultPricing,
	parseCreateEditFeedIngredientData,
	clearCreateEditFeedIngredientData,
	getFeedIngredientMarketPrice,
	convertTonsToPricingUnit,
	getPricingUnitForFeedIngredient,
	findNearestFuture,
	FeedPriceType,
	DefaultPricing,
};
