import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import BusinessesBusinessLivestockFeedIngredientsRoute from 'vault-client/routes/businesses/business/livestock-feed-ingredients';
import { FeedIngredient } from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';

enum FeedPriceType {
	'Corn' = 'Corn Based',
	'Soybean' = 'Soybean Meal Based',
	'Spot' = 'Spot',
}

interface FeedIngredientRow {
	id: string;
	name: string;
	dryMatterPercent: number;
	feedPriceType: string;
}

export default class LivestockFeedIngredientsController extends Controller {
	@tracked feedIngredientToEdit: FeedIngredient | null = null;
	@tracked feedIngredientToDelete: FeedIngredient | null = null;

	feedUsageRoutePath = '';
	ingredientDetailRoutePath = '';
	declare model: ModelFrom<BusinessesBusinessLivestockFeedIngredientsRoute>;

	get columns(): TableColumn[] {
		return [
			{
				id: '48b9c7bd-2e0b-425a-81c0-7a03abebe483',
				name: 'Ingredient',
				valuePath: 'name',
				textAlign: 'left',
				width: 120,
				cellComponent: CellComponents.String,
				linkRoute: this.ingredientDetailRoutePath,
				linkModelPath: 'id',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '564ba485-0c5c-4cff-90db-543d17b99b4c',
				name: 'DMI %',
				valuePath: 'dryMatterPercent',
				textAlign: 'right',
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ee567083-89b7-496b-8295-28927676e673',
				name: 'Feed Price Type',
				valuePath: 'feedPriceType',
				textAlign: 'left',
				width: 150,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'eb641cc9-6445-47f7-be20-20a74d3f7250',
				name: 'Feed Category',
				valuePath: 'feedCategoryName',
				textAlign: 'left',
				width: 150,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '9f044d8c-cd87-430e-90da-ed7a277fa451',
				name: 'Default Price per Ton',
				valuePath: 'defaultFlatPricePerTon',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				width: 210,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '02b5f59d-c67a-4843-aa1b-046bf93dbe87',
				name: 'Default CME Basis ($)',
				valuePath: 'defaultCmeUsdBasis',
				textAlign: 'right',
				width: 190,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1cab076e-5b9b-44ce-97e1-92e4112a2426',
				name: 'Default CME Basis (%)',
				valuePath: 'defaultCmePercentageBasis',
				textAlign: 'right',
				width: 190,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '539a8044-a850-4251-b77c-aa87ee7cf4d8',
				name: '',
				valuePath: 'id',
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Edit',
					fn: this.editIngredient,
					rightIconHref: '/icons/Edit-Outline.svg#edit-outline',
					rightIconClass: 'icon-interactive-stroke',
				},
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c88f82bf-8663-4130-830a-6005901c22ba',
				name: '',
				valuePath: 'id',
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.deleteIngredient,
					rightIconHref: '/icons/Delete-Outline.svg#delete-outline',
					rightIconClass: 'icon-interactive-stroke',
				},
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get rows() {
		return (
			this.model.getFeedIngredients.data?.FeedIngredients.map((ingredient) => {
				const row: {
					id: string;
					name: string;
					dryMatterPercent: number;
					businessId: string;
					feedPriceType?: FeedPriceType;
					defaultCmePercentageBasis?: number;
					defaultCmeUsdBasis?: number;
					defaultFlatPricePerTon?: number;
					feedCategoryName?: string;
				} = {
					id: ingredient.id,
					name: ingredient.name,
					dryMatterPercent: ingredient.dryMatterPercent,
					businessId: this.model.id,
				};

				const displayFactor = ingredient.FeedCategory.HedgeProduct?.MostCurrentFuture?.SymbolGroup.displayFactor ?? 1;

				row.feedCategoryName = ingredient.FeedCategory.name;

				// Prefer to only display pricing set on ingredient, fallback to pricing set on feed category
				if (ingredient.flatPricePerTon != null) {
					row.defaultFlatPricePerTon = ingredient.flatPricePerTon;
				} else if (ingredient.cmeUsdBasis != null) {
					row.defaultCmeUsdBasis = ingredient.cmeUsdBasis * displayFactor;
				} else if (ingredient.cmePercentageBasis != null) {
					row.defaultCmePercentageBasis = ingredient.cmePercentageBasis;
				} else if (ingredient.FeedCategory.defaultFlatPricePerTon != null) {
					row.defaultFlatPricePerTon = ingredient.FeedCategory.defaultFlatPricePerTon;
				} else if (ingredient.FeedCategory.defaultCmeUsdBasis != null) {
					row.defaultCmeUsdBasis = ingredient.FeedCategory.defaultCmeUsdBasis * displayFactor;
				} else if (ingredient.FeedCategory.defaultCmePercentageBasis != null) {
					row.defaultCmePercentageBasis = ingredient.FeedCategory.defaultCmePercentageBasis;
				}

				if (row.defaultFlatPricePerTon != null) {
					row.feedPriceType = FeedPriceType.Spot;
				} else if (row.defaultCmeUsdBasis != null || row.defaultCmePercentageBasis != null) {
					if (ingredient.FeedCategory.HedgeProduct?.slug === 'grain-corn') {
						row.feedPriceType = FeedPriceType.Corn;
					} else if (ingredient.FeedCategory.HedgeProduct?.slug === 'grain-soybean-meal') {
						row.feedPriceType = FeedPriceType.Soybean;
					}
				}

				return row;
			}) ?? []
		);
	}

	@action
	editIngredient(row: FeedIngredientRow) {
		this.feedIngredientToEdit = this.model.getFeedIngredients.data?.FeedIngredients.find((ingredient) => ingredient.id === row.id) ?? null;
	}

	@action
	onCreateEditFeedIngredientClose() {
		this.feedIngredientToEdit = null;
	}

	@action
	deleteIngredient(row: FeedIngredientRow) {
		this.feedIngredientToDelete =
			this.model.getFeedIngredients.data?.FeedIngredients.find((ingredient) => ingredient.id === row.id) ?? null;
	}
}
