import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { inject as service } from '@ember/service';
import BusinessesBusinessFeedIngredientsDetailRoute from 'vault-client/routes/businesses/business/feed-ingredient';
import { FeedIngredient, Product } from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { FeedIngredientBase } from 'vault-client/types/vault-client';
import RouterService from '@ember/routing/router-service';
import { guidFor } from '@ember/object/internals';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { UiTagStatus } from 'vault-client/components/ui-tag';
import MarketDataService from 'vault-client/services/market-data';
import { generateFeedBoughtHedgedMonths } from 'vault-client/utils/generate-feed-bought-hedged-months';

interface UsageWithPricingMonth {
	date: string;
	usageInTons: number;
	unhedgedInTons: number;
	unhedgedPercent: number;
	price: number | null;
	spotPrice: number | null;
	instrument: string | null;
	cmeBasisPrice: number | null;
	cmeBasisPricePercent: number | null;
}

type PricingFactor = 'Spot' | 'BasisUSD' | 'BasisPercent';

export default class FeedIngredientDetailController extends Controller {
	@service media: any;
	@service declare router: RouterService;
	@service declare marketData: MarketDataService;

	@tracked startDate: string = DateTime.now().set({ month: 10 }).startOf('month').minus({ year: 1 }).toISODate();
	@tracked endDate: string = DateTime.now().set({ month: 9 }).endOf('month').toISODate();
	@tracked usageWithPricingTableSelectedRows = [];
	@tracked openOrdersVendorId: string | null = null;
	@tracked purchasesVendorId: string | null = null;
	@tracked registeredFutures: Record<string, number> = {};

	id = guidFor(this);
	queryParams = ['startDate', 'endDate', 'openOrdersVendorId', 'purchasesVendorId'];
	feedIngredientRoutePath = '';
	feedOverviewRoutePath = '';
	feedOrdersRoutePath = '';
	feedPurchasesRoutePath = '';
	declare model: ModelFrom<BusinessesBusinessFeedIngredientsDetailRoute>;

	timePeriodOptions = [
		{
			displayName: `Feed Year ${DateTime.now().minus({ year: 1 }).year}`,
			startDate: DateTime.now().set({ month: 10 }).minus({ year: 2 }).startOf('month').toISODate(),
			endDate: DateTime.now().set({ month: 9 }).minus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: `Feed Year ${DateTime.now().year}`,
			startDate: DateTime.now().set({ month: 10 }).startOf('month').minus({ year: 1 }).toISODate(),
			endDate: DateTime.now().set({ month: 9 }).endOf('month').toISODate(),
		},
		{
			displayName: `Feed Year ${DateTime.now().plus({ year: 1 }).year}`,
			startDate: DateTime.now().set({ month: 10 }).startOf('month').toISODate(),
			endDate: DateTime.now().set({ month: 9 }).plus({ year: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	];

	get customerId() {
		return this.model.getFeedIngredient.data?.FeedIngredient?.Customer.id;
	}

	get currentTimePeriodOption() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get ingredient(): FeedIngredient | null {
		return this.model.getFeedIngredient.data?.FeedIngredient ?? null;
	}

	get ingredientId(): string {
		return this.ingredient?.id ?? '';
	}

	get ingredientName(): string {
		return this.ingredient?.name ?? '';
	}

	get ingredientBase(): FeedIngredientBase | null {
		return this.model.ingredientBase;
	}

	get hedgeProduct(): Product | null {
		return this.ingredient?.FeedCategory.HedgeProduct ?? null;
	}

	get vendors() {
		return this.model.getFeedIngredient.data?.Sellers;
	}

	get lastUpdatedAtString() {
		return `Last Updated: ${this.model.lastUpdatedAt}`;
	}

	get feedIngredient() {
		return this.model.getFeedIngredient.data?.FeedIngredient;
	}

	get futures() {
		const futures = this.model.getHedgedPositions.data?.Futures ?? [];
		return futures.sortBy('displayExpiresAt');
	}

	get boughtVsHedgedCardHeader() {
		switch (this.ingredientBase) {
			case FeedIngredientBase.Corn:
				return 'Corn Based Ingredients';

			case FeedIngredientBase.SoybeanMeal:
				return 'Soybean Meal Based Ingredients';

			default:
				return 'Other Ingredients';
		}
	}

	get openOrdersVendor() {
		if (!this.openOrdersVendorId) return null;

		return this.model.getFeedIngredient.data?.Sellers.find((seller) => seller.id === this.openOrdersVendorId) ?? null;
	}

	get purchasesVendor() {
		if (!this.purchasesVendorId) return null;

		return this.model.getFeedIngredient.data?.Sellers.find((seller) => seller.id === this.purchasesVendorId) ?? null;
	}

	get previousFeedIngredient() {
		const idx = this.model.getFeedIngredient.data?.FeedIngredients.findIndex((value) => value.id === this.ingredientId) ?? 0;
		const previousIdx = idx - 1;
		if (previousIdx < 0) return null;

		return this.model.getFeedIngredient.data?.FeedIngredients[previousIdx] ?? null;
	}

	get nextFeedIngredient() {
		const idx = this.model.getFeedIngredient.data?.FeedIngredients.findIndex((value) => value.id === this.ingredientId) ?? 0;
		const nextIdx = idx + 1;
		const length = this.model.getFeedIngredient.data?.FeedIngredients.length ?? 0;
		if (nextIdx >= length) return null;

		return this.model.getFeedIngredient.data?.FeedIngredients[nextIdx] ?? null;
	}

	get showPurchasesAndHedgesRow() {
		// Only show this table for corn and soy because physical hedges are not yet available. Always show once physical hedges are added.
		return this.ingredientBase === FeedIngredientBase.Corn || this.ingredientBase === FeedIngredientBase.SoybeanMeal;
	}

	get boughtVsHedgedLegendId() {
		return `bought-vs-hedged-legend-${this.id}`;
	}

	get boughtVsHedgedChartId() {
		return `bought-vs-hedged-chart-${this.id}`;
	}

	get pricingFactor(): PricingFactor {
		if (this.feedIngredient?.flatPricePerTon) {
			return 'Spot';
		} else if (this.feedIngredient?.cmeUsdBasis) {
			return 'BasisUSD';
		} else if (this.feedIngredient?.cmePercentageBasis) {
			return 'BasisPercent';
		} else if (this.feedIngredient?.FeedCategory?.defaultFlatPricePerTon) {
			return 'Spot';
		} else if (this.feedIngredient?.FeedCategory?.defaultCmeUsdBasis) {
			return 'BasisUSD';
		} else if (this.feedIngredient?.FeedCategory?.defaultCmePercentageBasis) {
			return 'BasisPercent';
		} else {
			return 'Spot';
		}
	}

	get feedBoughtHedgedMonths() {
		return generateFeedBoughtHedgedMonths(
			this.hedgeProduct?.slug ?? null,
			this.startDate,
			this.endDate,
			this.model.getFeedUsage.data?.AggregateFeedIngredientForecastedUsages ?? [],
			this.model.getHedgedPositions.data?.AggregateAllocatedForecastedHedgedAndCappedVolumes ?? [],
		);
	}

	get usageWithPricingColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '39e1cadf-81c9-464a-b8e2-deff73e60e48',
				name: 'Month',
				valuePath: 'date',
				textAlign: 'left',
				width: 80,
				cellComponent: CellComponents.MonthFormat,
				isSortable: false,
				isFixed: 'left',
				isVisible: true,
			},
			{
				id: '5146c04d-3a3d-4819-819c-4460ce1ddf72',
				name: 'Usage DMI Tons',
				valuePath: 'usageInTons',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				},
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];

		const priceColumn: TableColumn = {
			id: '0cfff6f2-f441-4c29-9a2a-d6a5d5493210',
			name: 'Price',
			valuePath: 'price',
			textAlign: 'right',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
			},
			width: 100,
			isSortable: false,
			isFixed: '',
			isVisible: true,
		};

		const unhedgedColumns: TableColumn[] = [
			{
				id: 'd2e36c41-92f3-4fde-948d-26a7837d0353',
				name: 'Unhedged',
				textAlign: '',
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '96057ab7-b338-4e6a-89fa-37a5e3acde5b',
						name: 'DMI Tons',
						textAlign: 'right',
						valuePath: 'unhedgedInTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						width: 110,
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '96057ab7-b338-4e6a-89fa-37a5e3acde5b',
						name: '%',
						textAlign: 'right',
						valuePath: 'unhedgedPercent',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 50,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];

		const spotColumn: TableColumn = {
			id: '026995bd-9f79-4b49-86d4-10aca0e47588',
			name: 'Spot Price',
			valuePath: 'spotPrice',
			textAlign: 'right',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
			},
			width: 100,
			isSortable: false,
			isFixed: '',
			isVisible: true,
		};

		const cornBasedPriceColumn: TableColumn = {
			id: '2d91c6f8-f495-46e7-9205-629bd3e40926',
			name: 'Corn-Based Price',
			valuePath: '',
			textAlign: '',
			cellComponent: CellComponents.String,
			isSortable: false,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '8418bb7a-cda2-414f-bbcb-caf5309422d2',
					name: 'Instrument',
					valuePath: 'instrument',
					textAlign: 'left',
					cellComponent: CellComponents.String,
					width: 110,
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		};

		if (this.pricingFactor === 'Spot') {
			cornBasedPriceColumn.subcolumns?.push(spotColumn);
		} else if (this.pricingFactor === 'BasisUSD') {
			cornBasedPriceColumn.subcolumns?.push({
				id: '104ad0ba-3e80-46b5-b7a7-2c03b0dcd977',
				name: 'CME Basis($) Price',
				valuePath: 'cmeBasisPrice',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				minWidth: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			});
		} else if (this.pricingFactor === 'BasisPercent') {
			cornBasedPriceColumn.subcolumns?.push({
				id: 'cc9a6350-bab7-480a-b316-2524e303307a',
				name: 'CME Basis(%) Price',
				valuePath: 'cmeBasisPricePercent',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				minWidth: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			});
		}

		const soyBasedPriceColumn: TableColumn = {
			id: 'a2f64a9f-ddb1-4164-aa81-fef430d2ef15',
			name: 'Soy-Based Price',
			valuePath: '',
			textAlign: '',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			isSortable: false,
			subcolumns: [
				{
					id: '6f292dde-5797-4748-955e-e1958e798076',
					name: 'Instrument',
					valuePath: 'instrument',
					textAlign: 'left',
					cellComponent: CellComponents.String,
					width: 105,
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		};

		if (this.pricingFactor === 'Spot') {
			soyBasedPriceColumn.subcolumns?.push(spotColumn);
		} else if (this.pricingFactor === 'BasisUSD') {
			soyBasedPriceColumn.subcolumns?.push({
				id: '2a21e520-8064-40a8-85e6-c08418321a90',
				name: 'CME Basis($) Price',
				valuePath: 'cmeBasisPrice',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			});
		} else if (this.pricingFactor === 'BasisPercent') {
			soyBasedPriceColumn.subcolumns?.push({
				id: 'c3d4b593-1fdf-418b-9c01-642d0d233747',
				name: 'CME Basis(%) Price',
				valuePath: 'cmeBasisPricePercent',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			});
		}

		if (this.ingredientBase === FeedIngredientBase.Corn) {
			return [...baseColumns, priceColumn, ...unhedgedColumns, cornBasedPriceColumn];
		} else if (this.ingredientBase === FeedIngredientBase.SoybeanMeal) {
			return [...baseColumns, priceColumn, ...unhedgedColumns, soyBasedPriceColumn];
		} else {
			// Do not display unhedged columns because physical hedges are not available
			return [...baseColumns, priceColumn];
		}
	}

	get usageWithPricingRows(): UsageWithPricingMonth[] {
		return this.feedBoughtHedgedMonths.map((month) => {
			const future = this.futures.find((future) => future.displayExpiresAt >= month.date) ?? null;
			const barchartSymbol = future?.barchartSymbol ?? null;

			const usageWithPricingMonth: UsageWithPricingMonth = {
				date: month.date,
				usageInTons: month.usageInTons,
				unhedgedInTons: month.unhedgedInTons,
				unhedgedPercent: month.unhedgedPercent,
				instrument: barchartSymbol,
				spotPrice: null,
				cmeBasisPrice: null,
				cmeBasisPricePercent: null,
				price: null,
			};

			if (this.feedIngredient?.flatPricePerTon) {
				usageWithPricingMonth.spotPrice = this.feedIngredient.flatPricePerTon;
			} else if (this.feedIngredient?.cmeUsdBasis) {
				usageWithPricingMonth.cmeBasisPrice = this.feedIngredient.cmeUsdBasis * (future?.SymbolGroup.displayFactor ?? 1);
			} else if (this.feedIngredient?.cmePercentageBasis) {
				usageWithPricingMonth.cmeBasisPricePercent = this.feedIngredient.cmePercentageBasis;
			} else if (this.feedIngredient?.FeedCategory?.defaultFlatPricePerTon) {
				usageWithPricingMonth.spotPrice = this.feedIngredient.FeedCategory.defaultFlatPricePerTon;
			} else if (this.feedIngredient?.FeedCategory?.defaultCmeUsdBasis) {
				usageWithPricingMonth.cmeBasisPrice =
					this.feedIngredient.FeedCategory.defaultCmeUsdBasis * (future?.SymbolGroup.displayFactor ?? 1);
			} else if (this.feedIngredient?.FeedCategory?.defaultCmePercentageBasis) {
				usageWithPricingMonth.cmeBasisPricePercent = this.feedIngredient.FeedCategory.defaultCmePercentageBasis;
			}

			if (usageWithPricingMonth.spotPrice) {
				usageWithPricingMonth.price = usageWithPricingMonth.spotPrice;
			} else if (usageWithPricingMonth.cmeBasisPrice && future && barchartSymbol) {
				const futurePrice = this.fetchFuturePrice(barchartSymbol);
				if (futurePrice) {
					const price = futurePrice * future.SymbolGroup.displayFactor + usageWithPricingMonth.cmeBasisPrice;
					usageWithPricingMonth.price = price;
				}
			} else if (usageWithPricingMonth.cmeBasisPricePercent && future && barchartSymbol) {
				const futurePrice = this.fetchFuturePrice(barchartSymbol);
				if (futurePrice) {
					const price = futurePrice * usageWithPricingMonth.cmeBasisPricePercent * future.SymbolGroup.displayFactor;
					usageWithPricingMonth.price = price;
				}
			}

			return usageWithPricingMonth;
		});
	}

	get usageWithPurchasesColumns(): TableColumn[] {
		// Unused for now, will be used later
		// @ts-expect-error TS error(6133) unused variable
		const _physicalHedgeColumns = [
			{
				id: '78a0fac5-05c7-470c-82a3-b04e4064116f',
				name: 'Basis Only',
				valuePath: '',
				cellComponent: CellComponents.String,
				textAlign: '',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '0335c05b-c0c0-46b3-bace-8b4385fb1d14',
						name: 'DMI Tons',
						valuePath: 'basisLockedInTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '8ef5b01b-ef44-4d04-ba3c-acb7dc72242c',
						name: '%',
						valuePath: 'basisLockedPercent',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '5e50a64e-45ae-4cef-bad7-7b8e8be5fd58',
				name: 'Instrument Only',
				valuePath: '',
				cellComponent: CellComponents.String,
				textAlign: '',
				isFixed: '',
				isSortable: false,
				isVisible: true,
				subcolumns: [
					{
						id: 'b698fb53-0249-49b7-89f3-a398a4fc4a72',
						name: 'DMI Tons',
						valuePath: 'futureLockedInTons',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '45a6ce08-a31b-4bf8-ba9e-608fe0032e09',
						name: '%',
						valuePath: 'futureLockedPercent',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];

		const financialHedgeColumns = [
			{
				id: 'a588ad70-1431-4dc9-ae61-56ed4740061a',
				name: 'Futures Hedged',
				valuePath: '',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '8bd54aa4-bc95-4660-92ae-4faf7d61722b',
						name: 'DMI Tons',
						valuePath: 'futuresHedgedInTons',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'a2b91c49-27fa-493f-ae96-7a683cde2611',
						name: 'CME Contracts',
						valuePath: 'futuresHedgedInContracts',
						width: 125,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '50c3f786-262c-425e-953c-4ef78e76112f',
						name: '%',
						valuePath: 'futuresHedgedPercent',
						cellComponent: CellComponents.IntlNumberFormat,
						width: 50,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '298e7e41-bb52-425c-8552-826c093d2ac2',
				name: 'Calls Hedged',
				valuePath: '',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '8bd54aa4-bc95-4660-92ae-4faf7d61722b',
						name: 'DMI Tons',
						valuePath: 'callsHedgedInTons',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '24348609-e038-454b-9c25-562bb96321a0',
						name: 'CME Contracts',
						valuePath: 'callsHedgedInContracts',
						width: 125,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '50c3f786-262c-425e-953c-4ef78e76112f',
						name: '%',
						valuePath: 'callsHedgedPercent',
						width: 50,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'c23e8385-f4af-428e-8be7-7267a0965c8e',
				name: 'Unhedged',
				valuePath: '',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '4d64d4ba-4af5-4edb-8997-5123ca570dae',
						name: 'DMI Tons',
						valuePath: 'unhedgedInTons',
						cellComponent: CellComponents.IntlNumberFormat,
						width: 110,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '79d0ec9b-59a1-4cb6-822d-69b502f5ec61',
						name: 'CME Contracts',
						valuePath: 'unhedgedInContracts',
						width: 125,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'ca982106-04f9-4c83-a525-9bc0fbda526e',
						name: '%',
						valuePath: 'unhedgedPercent',
						width: 50,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						isSortable: false,
						textAlign: 'right',
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];

		return [
			{
				id: 'a0024d18-6e2f-49d9-b9de-73714eb811da',
				name: 'Month',
				valuePath: 'date',
				textAlign: 'left',
				width: 80,
				cellComponent: CellComponents.MonthFormat,
				isSortable: false,
				isFixed: 'left',
				isVisible: true,
			},
			{
				id: '5146c04d-3a3d-4819-819c-4460ce1ddf72',
				name: 'Usage DMI Tons',
				valuePath: 'usageInTons',
				textAlign: 'right',
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				},
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			// {
			// 	id: '2cb175b0-a2a8-4a18-9f81-dd563de72d4c',
			// 	name: 'Bought',
			// 	valuePath: '',
			// 	cellComponent: CellComponents.String,
			// 	isFixed: '',
			// 	isVisible: true,
			// 	subcolumns: [
			// 		{
			// 			id: 'fdb38e4c-c24e-4509-998c-1011aea1b3c0',
			// 			name: 'DMI Tons',
			// 			valuePath: 'boughtInTons',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			width: 110,
			// 			componentArgs: {
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			isSortable: false,
			// 			textAlign: 'right',
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 		{
			// 			id: '7784f98e-da90-4735-8c16-0437a98e4b01',
			// 			name: '%',
			// 			valuePath: 'boughtPercent',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			width: 50,
			// 			componentArgs: {
			// 				style: 'percent',
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			isSortable: false,
			// 			textAlign: 'right',
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 	],
			// 	 ...(this.ingredientBase === FeedIngredientBase.Corn || this.ingredientBase === FeedIngredientBase.Soy
			// 	 	? physicalHedgeColumns
			// 		: []),
			// },

			...(this.ingredientBase === FeedIngredientBase.Corn || this.ingredientBase === FeedIngredientBase.SoybeanMeal
				? financialHedgeColumns
				: []),
		];
	}

	get openOrdersColumns(): TableColumn[] {
		return [
			{
				id: '061435cb-cda9-4794-8fce-4c6216363257',
				name: 'Vendor',
				valuePath: 'vendor',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'dc674c25-7867-4140-a5aa-b80cc16f71ff',
				name: 'Updated At',
				valuePath: 'updatedAt',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '81b238ed-d671-4e5d-aaf3-fbf5e1325473',
				name: 'Status',
				valuePath: 'status',
				cellComponent: CellComponents.Tag,
				componentArgs: {
					statusFn: this.tagStatusFunction,
				},
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '2e1761ae-42d5-4e02-be84-10cc9ecc0343',
				name: 'ExpiresAt',
				valuePath: 'expiresAt',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '390bbd96-6a34-4d28-8cd1-d207f4f4baf3',
				name: 'Tons',
				valuePath: 'tons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '761a5973-1d39-4a4a-9c6c-dd7623bdc515',
				name: 'DMI Tons',
				valuePath: 'dmiTons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'c89fcdf1-955a-4bcd-b3bf-e19b430f49cb',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '85426ab0-5a30-4308-8944-84f5776eb3e8',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '894779ef-304b-4b23-b37b-3cf6d8b64649',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'ff26d4f2-6986-46e6-9848-64726b1b06fd',
				name: 'Sales Type',
				valuePath: 'salesType',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'be11bb1c-fd89-44da-90b1-0213783b8e55',
				name: 'Futures Price',
				valuePath: 'futuresPrice',
				cellComponent: CellComponents.MarketPriceFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '3e12b7fe-1293-4b92-8513-81632b145200',
				name: 'Basis',
				valuePath: 'basis',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '97bba0be-1cc7-4519-9064-2e09daec5fb0',
				name: 'Seller',
				valuePath: 'seller',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'da787ace-2665-42ef-9a48-61a543f444f2',
				name: 'Location',
				valuePath: 'location',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'f91431f4-7251-4cc9-8653-67d2f8b4301d',
				name: 'Delivery',
				valuePath: 'delivery',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'b6d420fc-b385-4772-8a69-f151b41e5d1d',
				name: 'FOB',
				valuePath: 'fob',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '53ff3da9-9c58-410a-8ccf-0b8387e631fa',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
		];
	}

	get openOrdersRows() {
		return [
			{
				vendor: 'Clark Foods',
				updatedAt: DateTime.now().minus({ days: 20 }).toFormat('L/d/y, t'),
				status: 'Pending',
				tons: 500,
				futuresMonth: DateTime.now().plus({ month: 2 }).startOf('month').toISODate(),
				deliveryStartDate: DateTime.now().plus({ month: 1 }).toISODate(),
				deliveryEndDate: DateTime.now().plus({ month: 1, days: 20 }).toISODate(),
				salesType: 'Basis',
				basis: 0.98,
				seller: 'Clark',
				location: 'Clinton, IA',
				delivery: 'Clinton, IA',
				contractNumber: '998904',
			},
			{
				vendor: 'Daisy Farms',
				updatedAt: DateTime.now().minus({ days: 10 }).toFormat('L/d/y, t'),
				status: 'Pending',
				tons: 250,
				dmiTons: 200,
				futuresMonth: DateTime.now().plus({ month: 2 }).startOf('month').toISODate(),
				deliveryStartDate: DateTime.now().plus({ month: 1 }).toISODate(),
				deliveryEndDate: DateTime.now().plus({ month: 1, days: 20 }).toISODate(),
				salesType: 'Flat',
				seller: 'Daisy',
				location: 'Kansas City, MO',
				delivery: 'Kansas City, MO',
				contractNumber: '0093774',
			},
		];
	}

	get feedPurchasesColumns(): TableColumn[] {
		return [
			{
				id: '94ef1f67-a4ef-48b1-9fa2-eea4148e01bd',
				name: 'Vendor',
				valuePath: 'vendor',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '017dc23a-21ec-4f82-93f7-cc1621bf6612',
				name: 'Tons',
				valuePath: 'tons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '7d368cf7-9462-41f1-98ec-3c32e0f2e467',
				name: 'DMI Tons',
				valuePath: 'dmiTons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'eac718e4-67e9-4d8a-8d70-cacbb5900aad',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'cc3eddcb-f441-4b73-b419-2fcb3ad98c23',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '836e792b-5953-4189-819e-da71d65aed0a',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '559610ca-3694-4f4f-94fb-fd4192962213',
				name: 'Sales Type',
				valuePath: 'deliveryStartDate',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'bf1fda6a-b11a-4e21-8ed5-ccc08bafab80',
				name: 'Futures Price',
				valuePath: 'futuresPrice',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'c703631f-409b-4094-a2f2-c1075f311054',
				name: 'Basis',
				valuePath: 'basis',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '7d71688b-db33-41c7-b528-307ee908f552',
				name: 'Seller',
				valuePath: 'seller',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '35783e72-a814-410a-8e15-e4adb7002382',
				name: 'Location',
				valuePath: 'location',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'def1e677-4c12-4c85-bdfa-86da9337fecf',
				name: 'Delivery',
				valuePath: 'delivery',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'd7a2a542-8c19-4a05-8de6-4ad193154d7d',
				name: 'FOB',
				valuePath: 'fob',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'd62ce03e-6e87-4a1a-932d-305f9024aa76',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				cellComponent: CellComponents.String,
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
		];
	}

	get feedPurchasesRows() {
		return [];
	}

	@action
	tagStatusFunction(text: string | undefined): UiTagStatus {
		switch (text) {
			case 'Filled':
				return 'positive';
			case 'Pending':
				return 'warning';
			default:
				return 'neutral';
		}
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	@action
	setOpenOrdersVendorId(id: string | null, callback?: () => void) {
		this.openOrdersVendorId = id;

		if (callback) {
			callback();
		}
	}

	@action
	setPurchasesVendorId(id: string | null, callback?: () => void) {
		this.purchasesVendorId = id;

		if (callback) {
			callback();
		}
	}

	@action
	transitionToFeedIngredientDetail(id: string) {
		this.router.transitionTo(this.feedIngredientRoutePath, id);
	}

	@action
	transitionToFeedOrders() {
		if (this.customerId && this.ingredientId !== '') {
			this.router.transitionTo(this.feedOrdersRoutePath, this.customerId);
		}
	}

	@action
	transitionToFeedPurchases() {
		if (this.customerId && this.ingredientId !== '') {
			this.router.transitionTo(this.feedPurchasesRoutePath, this.customerId);
		}
	}

	fetchFuturePrice(symbol: string) {
		const marketDatum = this.marketData.getMarketDatum(symbol) as
			| {
					previousSettlement: number | undefined;
					close: number | undefined;
					last: number | undefined;
					settlement: number | undefined;
					lastPrice: number | undefined;
			  }
			| undefined;

		if (marketDatum) {
			return (
				marketDatum.previousSettlement || marketDatum.settlement || marketDatum.close || marketDatum.last || marketDatum.lastPrice || null
			);
		} else if (!this.registeredFutures[symbol]) {
			this.marketData.register(symbol);
			this.registeredFutures[symbol] = 1;
		}
		return null;
	}
}
