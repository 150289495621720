import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import LivestockFeedIngredientDetailRoute, {
	FeedIngredientQueryArgs,
	GetFeedIngredientQuery,
	GetFeedUsageQuery,
	GetFeedUsageQueryArgs,
	GetHedgedPositionsQuery,
	GetHedgedPositionsQueryArgs,
} from 'vault-client/routes/livestock-feed/ingredients/detail';
import { FeedIngredientBase } from 'vault-client/types/vault-client';

interface ModelParams {
	ingredient_id: string;
	startDate: string;
	endDate: string;
}

// TODO: Add Seller/Vendor to query
const GET_FEED_INGREDIENT = gql`
	query LivestockFeedIngredient($id: String!, $feedIngredientsWhere: FeedIngredientFilterDTO) {
		FeedIngredient(id: $id) {
			id
			name
			description
			dryMatterPercent
			flatPricePerTon
			cmeUsdBasis
			cmePercentageBasis
			versionedConceptSeriesId
			Customer {
				id
			}
			FeedCategory {
				id
				name
				defaultFlatPricePerTon
				defaultCmePercentageBasis
				defaultCmeUsdBasis
				HedgeProduct {
					id
					slug
					name
					CurrentFutures {
						id
						type
						barchartSymbol
						displayExpiresAt
						SymbolGroup {
							displayFactor
							fractionDigits
						}
					}
				}
			}
		}
		FeedIngredients(where: $feedIngredientsWhere, orderBy: { id: Asc }) {
			id
			name
			versionedConceptSeriesId
		}
	}
`;

const GET_FEED_USAGE = gql`
	query LivestockFeedUsage($versionedConceptSeriesId: String!, $entityId: String!, $startDate: String!, $endDate: String!) {
		AggregateForecastedSwineLivestockFeedUsages(
			where: {
				date: { gt: $startDate, lt: $endDate }
				businessId: { equals: $entityId }
				Ingredient: { versionedConceptSeriesId: { equals: $versionedConceptSeriesId } }
			}
			calc: { sum: { quantityInLbs: true } }
			groupBy: {
				firstDateOfMonth: true
				Ingredient: { id: true, dryMatterPercent: true, FeedCategory: { id: true, HedgeProduct: { id: true, slug: true } } }
			}
		) {
			firstDateOfMonth
			sum {
				quantityInLbs
			}
			Ingredient {
				id
				dryMatterPercent
				FeedCategory {
					id
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
	}
`;

const GET_HEDGED_POSITIONS = gql`
	query HedgedPositions(
		$forecastedHedgedAndCappedVolumeWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO
		$futuresWhere: FutureFilterDTO
	) {
		AggregateAllocatedForecastedHedgedAndCappedVolumes(
			calc: { sum: { naturallyShortHedged: true } }
			groupBy: { instrumentType: true, date: true, optionType: true, Product: { id: true, slug: true } }
			where: $forecastedHedgedAndCappedVolumeWhere
		) {
			optionType
			instrumentType
			date
			Product {
				id
				slug
			}
			sum {
				naturallyShortHedged
			}
		}
		Futures(where: $futuresWhere) {
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
	}
`;

export default class BusinessesBusinessLivestockFeedIngredientDetailRoute extends LivestockFeedIngredientDetailRoute {
	async model(params: ModelParams) {
		const bussinessId = (this.paramsFor('businesses.business') as { business_id: string }).business_id;

		this.feedIngredientVariables = {
			id: params.ingredient_id,
			feedIngredientsWhere: this.generateFeedIngredientsWhere(bussinessId),
		};

		// Queries are defined in the model to ensure they are refetched each time the model in refreshed. Ensures Feed ingredient data does not get out of date after mutations (removes previous hack).
		// See `update-feed-usage-button` for comment on fetchPolicy
		const getFeedIngredient = useQuery<GetFeedIngredientQuery, FeedIngredientQueryArgs>(this, () => [
			GET_FEED_INGREDIENT,
			{ variables: this.feedIngredientVariables, fetchPolicy: 'network-only' },
		]);

		await getFeedIngredient.promise;

		const versionedConceptSeriesId = getFeedIngredient.data?.FeedIngredient?.versionedConceptSeriesId || '';

		this.feedUsageVariables = { versionedConceptSeriesId, startDate: params.startDate, endDate: params.endDate, entityId: bussinessId };

		const getFeedUsage = useQuery<GetFeedUsageQuery, GetFeedUsageQueryArgs>(this, () => [
			GET_FEED_USAGE,
			{ variables: this.feedUsageVariables, fetchPolicy: 'network-only' },
		]);

		await getFeedUsage.promise;

		const hedgedProductSlug = getFeedIngredient.data?.FeedIngredient?.FeedCategory.HedgeProduct?.slug;

		const ingredientBase = ((slug: string | undefined) => {
			switch (slug) {
				case 'grain-corn':
					return FeedIngredientBase.Corn;
				case 'grain-soybean-meal':
					return FeedIngredientBase.SoybeanMeal;
				default:
					return FeedIngredientBase.Other;
			}
		})(hedgedProductSlug);

		this.hedgedPositionsVariables = {
			forecastedHedgedAndCappedVolumeWhere: this.generateForecastedHedgedAndCappedVolumeWhere(
				ingredientBase,
				bussinessId,
				params.startDate,
				params.endDate
			),
			futuresWhere: this.generateFuturesWhere(ingredientBase, params.startDate, params.endDate),
		};

		const getHedgedPositions = useQuery<GetHedgedPositionsQuery, GetHedgedPositionsQueryArgs>(this, () => [
			GET_HEDGED_POSITIONS,
			{ variables: this.hedgedPositionsVariables, fetchPolicy: 'network-only' },
		]);

		await getHedgedPositions.promise;

		return {
			getFeedIngredient,
			getFeedUsage,
			getHedgedPositions,
			ingredientBase,
			lastUpdatedAt: DateTime.now().toFormat("LL/dd/yyyy 'at' t"),
			startDate: params.startDate,
			endDate: params.endDate,
		};
	}
}
