import Controller from '@ember/controller';
import { action } from '@ember/object';
import { camelize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import BusinessesBusinessLivestockFeedUsageRoute from 'vault-client/routes/businesses/business/livestock-feed-usage';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CurrentFeedIngredients, FeedIngredient, LivestockGroupFeedUsageVersion } from 'vault-client/types/graphql-types';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';

type FeedUsageRow = {
	[key: string]: number | string;
	date: string;
};

export function generateUsagePropertyName(livestockGroupName: string) {
	return camelize(livestockGroupName + ' Usage');
}

export default class livestockFeedUsageController extends Controller {
	MONTH_COLUMN_ID = '10cdc450-9c7c-4f09-9572-c08b5d8e506d';

	// Used to calculate a column width based on ingredient name length
	PIXELS_PER_HEADER_CHAR = 8;
	HEADER_PADDING = 32;

	@tracked startDate: string = DateTime.now().startOf('month').toISODate();
	@tracked endDate: string = DateTime.now().plus({ months: 11 }).endOf('month').toISODate();
	@tracked selectedRows: FeedUsageRow[] = [];

	@tracked showCreateNewIngredientModal: boolean = false;
	@tracked feedIngredients: FeedIngredient[] = [];
	@tracked versionId: string | undefined = undefined;
	@tracked showBanner = true;

	declare model: ModelFrom<BusinessesBusinessLivestockFeedUsageRoute>;

	feedIngredientRoutePath = '';

	uuidNamespace = '3008ac50-a9e3-45a1-ad95-a06c6c4a319e';

	queryParams = ['startDate', 'endDate', 'versionId'];

	get showSelectionCheckboxes() {
		return !this.selectedVersion || this.selectedVersion.isCurrent;
	}

	get isCurrentVersion() {
		return !this.selectedVersion || this.selectedVersion.isCurrent;
	}

	get selectedVersion() {
		return this.feedIngredientUsageVersions.find((version) => version.id === this.versionId);
	}

	get tableRoute() {
		return `livestock-feed-usage.${this.model.businessId}-table`;
	}

	get isUsageEmpty() {
		return !this.rows.some((v) => Object.values(v).filter((x) => !!x).length > 2);
	}

	get feedIngredientIdToName() {
		const dict: { [key: string]: string } = {};

		this.model.getFeedUsage.data?.FeedIngredients.forEach((ingredient) => {
			dict[ingredient.versionedConceptSeriesId] = ingredient.name;
		});

		return dict;
	}

	get ingredientsAreVisible() {
		const visibleCols = this.columns.filter((column) => column.isVisible);

		// If 2 or more columns are visible, then at least one ingredient is shown. If only one column is visible, ensure it is not the month column
		return visibleCols.length >= 2 || visibleCols.firstObject?.id !== this.MONTH_COLUMN_ID;
	}

	get activeFeedUsages() {
		return this.model.getFeedUsage.data?.SwineLivestockGroupFeedUsages.filter((usage) => usage.versionId === this.versionId) ?? [];
	}

	get feedIngredientUsageVersions() {
		const versions: Map<string, LivestockGroupFeedUsageVersion> = new Map();
		this.model.getFeedUsage.data?.SwineLivestockGroupFeedUsages.forEach((usage) => {
			if (versions.get(usage.Version.id) == undefined) {
				versions.set(usage.Version.id, usage.Version);
			}
		});

		// sort in desc order, prioritize isCurrent over updatedAt
		return Array.from(versions.values()).sort((a, b) => {
			if (a.isCurrent) return -1;
			if (b.isCurrent) return 1;

			if (a.updatedAt < b.updatedAt) return 1;
			if (a.updatedAt > b.updatedAt) return -1;
			return 0;
		});
	}

	get livestockGroups() {
		return this.model.getFeedUsage.data?.SwineLivestockGroups;
	}

	get lastUpdatedBy() {
		return this.selectedVersion?.LastUpdatedByUser
			? this.selectedVersion?.LastUpdatedByUser.firstName + ' ' + this.selectedVersion?.LastUpdatedByUser.lastName
			: this.selectedVersion?.CreatedByUser
				? this.selectedVersion?.CreatedByUser.firstName + ' ' + this.selectedVersion?.CreatedByUser.lastName
				: null;
	}
	get columns(): TableColumn[] {
		const swineGroups = this.model.getFeedUsage.data?.SwineLivestockGroups?.map((group) => {
			return {
				id: group.id,
				name: group.name,
				valuePath: generateUsagePropertyName(group.name),
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 4,
				},
				textAlign: 'right',
				width: group.name.length * this.PIXELS_PER_HEADER_CHAR + this.HEADER_PADDING,
				isVisible: true,
				isFixed: '',
			};
		});
		return [
			{
				id: '0aba4699-1f97-4545-a818-2a9a1ad4bf9d',
				name: 'Ingredient Name',
				valuePath: 'name',
				cellComponent: CellComponents.String,
				linkRoute: this.feedIngredientRoutePath,
				linkModelPath: 'id',
				isVisible: true,
				textAlign: 'left',
				isFixed: '',
			},
			{
				id: '9cc1977c-d5ef-4302-8eb5-302ab93a503c',
				name: 'Feed Category',
				valuePath: 'feedCategoryName',
				textAlign: 'left',
				width: 150,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			...(swineGroups ?? []),
		];
	}

	get rows() {
		const rowsMap = new Map();
		const feedIngredients = this.isCurrentVersion
			? this.feedIngredients
			: this.model.getFeedUsage.data?.SwineLivestockGroupFeedUsages.filter((usage) => usage.versionId === this.versionId).map(
					(usage) => usage.FeedIngredient,
				) ?? [];
		feedIngredients.forEach((ingredient) => {
			rowsMap.set(ingredient.id, {
				id: ingredient.id,
				name: ingredient?.name,
				versionedConceptSeriesId: ingredient.versionedConceptSeriesId,
				feedCategoryName: ingredient?.FeedCategory?.name,
			});
		});

		this.model.getFeedUsage.data?.SwineLivestockGroupFeedUsages.forEach((usage) => {
			const row = rowsMap.get(usage.feedIngredientId);
			if (this.versionId && usage.versionId === this.versionId && row) {
				row[generateUsagePropertyName(usage.LivestockGroup.name)] = usage.usageInTonsPerHead;
			}
		});
		return Array.from(rowsMap.values());
	}

	get inactiveFeedIngredients() {
		const activeIngredientsDict: { [id: string]: 1 | undefined } = {};
		this.feedIngredients.forEach((v) => {
			activeIngredientsDict[v.versionedConceptSeriesId] = 1;
		});

		return this.model.getFeedUsage.data?.FeedIngredients.filter((v) => !activeIngredientsDict[v.versionedConceptSeriesId]);
	}

	@action
	generateUpdatedByString(version: LivestockGroupFeedUsageVersion) {
		const dateString = DateTime.fromISO(version.updatedAt).toFormat('D t');
		const firstName = version.LastUpdatedByUser?.firstName;
		const firstNameString = firstName ? `Updated by ${firstName}` : null;

		return firstNameString ? `${firstNameString} ${dateString}` : dateString;
	}

	@action
	selectCurrentVersion() {
		this.versionId = this.feedIngredientUsageVersions.find((version) => version.isCurrent)?.id;
		this.showBanner = true;
		this.clearSelectedRows();
	}

	@action
	changeVersion(id: string) {
		this.versionId = id;
		this.showBanner = true;
		this.clearSelectedRows();
	}

	@action
	openCreateNewIngredientModal() {
		this.showCreateNewIngredientModal = true;
	}

	@action
	closeCreateNewIngredientModal() {
		this.showCreateNewIngredientModal = false;
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
		this.clearSelectedRows();
	}

	@action
	onUsageSubmit(currentVersion: LivestockGroupFeedUsageVersion | undefined) {
		const versionId = currentVersion?.id;

		if (!versionId) {
			this.versionId = undefined;
		} else if (versionId !== this.versionId) {
			this.versionId = versionId;
		}
		this.selectedRows = [];
	}

	@action
	onCreateUpdateFeedIngredient(currentFeedIngredients: CurrentFeedIngredients | null, ingredientName: string) {
		this.versionId = undefined;
		const ingredient = currentFeedIngredients?.FeedIngredients?.find((ingredient) => ingredient.name === ingredientName);
		if (!ingredient) return;

		this.addFeedIngredient(ingredient);
	}

	@action
	addFeedIngredient(ingredient: FeedIngredient | null) {
		if (!ingredient) return;

		for (const x of this.feedIngredients) {
			if (x.versionedConceptSeriesId === ingredient.versionedConceptSeriesId) {
				return;
			}
		}

		this.clearSelectedRows();
		this.feedIngredients = [...this.feedIngredients, ingredient];
		this.setStoredFeedIngredients();
	}

	@action
	removeFeedIngredient(ingredient: FeedIngredient) {
		this.feedIngredients = this.feedIngredients.filter((x) => {
			return x.versionedConceptSeriesId !== ingredient.versionedConceptSeriesId;
		});
		this.setStoredFeedIngredients();
	}

	@action
	setStoredFeedIngredients() {
		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem(
				`livestock-feed-usage.${this.model.businessId}`,
				JSON.stringify(this.feedIngredients.map((ingredient) => ingredient.versionedConceptSeriesId)),
			);
		}
	}

	@action
	fetchStoredFeedIngredients() {
		if (checkStorageAvailable('localStorage')) {
			this.feedIngredients = [];

			const temp = window.localStorage.getItem(`livestock-feed-usage.${this.model.businessId}`);
			if (temp) {
				const storedFeedIngredientIDs: string[] = JSON.parse(temp);
				for (const id of storedFeedIngredientIDs) {
					const ingredient = this.model.getFeedUsage.data?.FeedIngredients.find((ingredient) => {
						return id === ingredient.versionedConceptSeriesId;
					});

					if (ingredient) {
						this.feedIngredients = [...this.feedIngredients, ingredient];
					}
				}
			}
		}
	}

	@action
	clearSelectedRows() {
		this.selectedRows = [];
	}
}
